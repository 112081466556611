// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const adminUiTexts = {
	facilitatorBtn: {
		da: 'Facilitator',
		en: 'Facilitator'
	},
	createNewGame: {
		da: 'Opret nyt spil',
		en: 'Create new game'
	},
	downloadPlayerProgress: {
		da: 'Download medarbejderdata',
		en: 'Download employee data'
	},
	viewFullGameStats: {
		da: 'Se samlet statistik',
		en: 'View full statistics'
	},
	fullStats: {
		da: 'Samlet statistik for alle spil',
		en: 'Full statistics for all games'
	},
	name: {
		da: 'Navn',
		en: 'Name'
	},
	created: {
		da: 'Oprettet',
		en: 'Created'
	},
	createdBy: {
		da: 'Oprettet af',
		en: 'Created by'
	},
	scenario: {
		da: 'Scenarie',
		en: 'Scenario'
	},
	gameUrl: {
		da: 'URL',
		en: 'URL'
	},
	facilitators: {
		da: 'Facilitatorer',
		en: 'Facilitators'
	},
	invited: {
		da: 'Inviteret',
		en: 'Invited'
	},
	players: {
		da: 'Spillere',
		en: 'Players'
	},
	gameName: {
		da: 'Spilnavn',
		en: 'Game name'
	},
	viewGameInfo: {
		da: 'Se info',
		en: 'View info'
	},
	viewGameStats: {
		da: 'Se statistik',
		en: 'View stats'
	},

	surveyPopup: {
		allScenarios: {
			da: 'Alle scenarier',
			en: 'All scenarios'
		}
	},

	playerProgress: {
		employeeData: {
			da: 'Medarbejderdata',
			en: 'Employee data'
		},
		course: {
			da: 'Kursus',
			en: 'Course',
		},
		courseId: {
			da: 'Kursus id',
			en: 'Course id',
		},
		employeeId: {
			da: 'Medarbejder-nr.',
			en: 'Employee number'
		},
		startDate: {
			da: 'Start-dato (beståelsesdato) dd.mm.yyyy',
			en: 'Start date (completion date) dd.mm.yyyy'
		},
		endDate: {
			da: 'Slut-dato (udløbs-/fornyelsesdato) dd.mm.yyyy',
			en: 'End date (expiration / renewal date) dd.mm.yyyy'
		}
	},

	gamePopup: {
		addFacilitator: {
			da: 'Tilføj facilitator',
			en: 'Add facilitator'
		},
		removeFacilitator: {
			da: 'Fjern facilitator',
			en: 'Remove facilitator'
		},
		deleteGame: {
			da: 'Slet spil',
			en: 'Delete game'
		},
		email: {
			da: 'E-mail',
			en: 'E-mail'
		},
		lastLogin: {
			da: 'Sidste login',
			en: 'Last login'
		}
	},

	gameStatsPopup: {
		backBtn: {
			da: 'Tilbage',
			en: 'Back'
		},
		moduleGroup: {
			da: 'Modulgruppe',
			en: 'Module group'
		},
		module: {
			da: 'Modul',
			en: 'Module'
		},
		totalPlayers: {
			da: 'Spillere i alt',
			en: 'Total number of players'
		},
		numberOfPlayers: {
			da: 'Antal spillere',
			en: 'Number of players'
		},
		timeSpent: {
			da: 'Tid brugt (gns)',
			en: 'Time spent (avg)'
		},
		minutes: {
			da: 'minutter',
			en: 'minutes'
		},
		numberOfErrors: {
			da: 'Antal fejl (gns)',
			en: 'Number of errors (avg)'
		},
		completed: {
			da: 'Gennemført',
			en: 'Completed'
		},
		task: {
			da: 'Opgave',
			en: 'Task'
		},
		type: {
			da: 'Type',
			en: 'Type'
		},
		mistakes: {
			da: 'Fejl (gns)',
			en: 'Mistakes (avg)'
		},
		perfectAttempts: {
			da: 'Fejlfri (%)',
			en: 'Perfect (%)'
		},
		attempts: {
			da: 'Forsøg',
			en: 'Attempts'
		}
	},

	gameStatsDownload: {
		games: {
			da: 'Spil',
			en: 'Games'
		},
		generalStats: {
			da: 'Generelle stats',
			en: 'General stats'
		},
		downloadStats: {
			da: 'Download statistik',
			en: 'Download statistics'
		}
	},

	addGamePopup: {
		title: {
			da: 'Opret nyt spil',
			en: 'Create new game'
		},
		gameNamePlaceholder: {
			da: 'spilnavn',
			en: 'game name'
		},
		gameUrlPlaceholder: {
			da: 'spil url',
			en: 'game url'
		},
		facilitatorPlaceholder: {
			da: 'facilitator e-mail',
			en: 'facilitator e-mail'
		},
		okBtn: {
			da: 'Opret spil',
			en: 'Create game'
		},
		emptyField: {
			da: 'Husk at udfylde alle felter',
			en: 'Please fill out this field'
		},
		reservedUrl: {
			da: 'Denne URL er reserveret',
			en: 'This URL is reserved'
		},
		invalidUrl: {
			da: 'Ugyldig URL (eksempel: spil-1',
			en: 'Invalid URL (valid example: game-1)'
		},
		notUniqueUrl: {
			da: 'Denne URL er allerede i brug',
			en: 'This URL is already in use'
		},
		invalidName: {
			da: 'Ugyldigt navn',
			en: 'Invalid name'
		},
		invalidEmail: {
			da: 'Ugyldig e-mail',
			en: 'Invalid e-mail'
		}
	},

	addFacilitatorToGamePopup: {
		title: {
			da: 'Tilføj facilitator',
			en: 'Add facilitator'
		},
		emailPlaceholder: {
			da: 'email',
			en: 'email'
		},
		okBtn: {
			da: 'OK',
			en: 'OK'
		},
		emptyField: {
			da: 'Husk at udfylde e-mail-feltet.',
			en: 'Please fill out the e-mail field.'
		},
		invalidEmail: {
			da: 'Ugyldig e-mail.',
			en: 'Invalid e-mail format.'
		},
		alreadyFacilitator: {
			da: 'Denne e-mail er allerede facilitator af spillet.',
			en: 'This user is already a facilitator of this game.'
		}
	},

	removeFacilitatorFromGamePopup: {
		title: {
			da: 'Fjern facilitator fra spil',
			en: 'Remove facilitator from game'
		},
		text: {
			da: 'Er du sikker på, du vil fjerne %email%?<br /><br />Facilitatoren vil ikke længere have adgang til spillet.',
			en: 'Are you sure you want to remove the facilitator %email%?<br /><br />The facilitator will no longer have access to this game.'
		},
		confirmBtn: {
			da: 'Ja, fjern',
			en: 'Yes, remove'
		},
			
		cancelBtn: {
			da: 'Nej, afbryd',
			en: 'No, cancel'
		}
	},

	deleteGamePopup: {
		title: {
			da: 'Slet spil',
			en: 'Delete game'
		},
		text1: {
			da: 'Er du sikker på, du vil slette spillet %gameName%?<br /><br />Al data om spillet og dets spillere bliver slettet.',
			en: 'Are you sure you want to delete the game %gameName%?<br /><br />All game and player data will be deleted.',
		},
		text2: {
			da: `Bekræft venligst, at du vil slette %gameName%.`,
			en: `Please confirm that you want to delete %gameName%.`
		},
		confirmBtn: {
			da: 'Ja, slet',
			en: 'Yes, delete'
		},
		cancelBtn: {
			da: 'Nej, afbryd',
			en: 'No, cancel'
		}
	},

	textToSpeechPopup: {
		title: {
			da: 'Text to speech',
			en: 'Text to speech',
		},
		configuration: {
			da: 'Konfiguration',
			en: 'Configuration'
		},
		language: {
			da: 'Sprog',
			en: 'Language'
		},
		voice: {
			da: 'Stemme',
			en: 'Voice'
		},
		modules: {
			da: 'Moduler',
			en: 'Modules'
		},
		uploadBtn: {
			da: 'Upload tekster',
			en: 'Upload texts'
		}
	}
};

module.exports = {
	adminUiTexts
};