const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om hygiejne i selve produktionen.`,
			en: `Welcome to the second part of the hygiene training. Now we are going to talk about hygiene inside the production areas.`,
			pl: 'Witaj w drugiej części szkolenia z zasad higieny. Teraz porozmawiamy o higienie w zakładach produkcyjnych.',
			vn: 'Chào mừng bạn đến phần thứ hai của khóa đào tạo vệ sinh. Bây giờ chúng ta sẽ nói về việc vệ sinh bên trong khu vực sản xuất.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-dos-and-donts',
		text: {
			da: `Det handler om, hvordan du skal håndtere produkter i produktionen. Altså hvad man må gøre, og hvad man ikke må gøre i produktionen.`,
			en: `This is about how to handle products and about what is and isn't allowed in the production areas.`,
			pl: 'Omówimy, jak należy obchodzić się z produktami oraz co jest, a co nie jest dozwolone w obszarach produkcji.',
			vn: 'Điều này liên quan đến cách xử lý sản phẩm và về những yếu tô được và không được phép trong khu vực sản xuất.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-stars',
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre.`,
			en: `Let's start! Remember, you must get three stars to complete the module.`,
			pl: 'Zaczynajmy! Pamiętaj, musisz uzyskać trzy gwiazdki, aby ukończyć moduł.',
			vn: 'Bắt đầu nào! Hãy nhớ, bạn phải đạt được ba sao để hoàn thành mô-đun.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-hygiene-extra-careful',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why must we be especially aware on hygiene when making foods?`,
			pl: 'Dlaczego higiena jest szczególnie istotna przy produkcji produktów spożywczych?',
			vn: 'Tại sao chúng ta cần đặc biệt chú ý đến vệ sinh khi sản xuất thực phẩm?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Because of legal requirements`,
					pl: 'Ze względu na wymogi prawne',
					vn: 'Do các yêu cầu pháp lý'
				}				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because bad hygiene can make people sick`,
					pl: 'Ponieważ zła higiena może prowadzić do zachorowań',
					vn: 'Vì vệ sinh kém có thể khiến con người bị bệnh'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: 'Aby zapewnić wysoką jakość',
					vn: 'Để đảm bảo chất lượng cao'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the reasons above`,
					pl: 'Ze wszystkich powyższych przyczyn',
					vn: 'Vì tất cả những lý do trên'
				},
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-hygiene-issues',
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at the production. On the next image, you must spot the things that can be problematic when it comes to hygiene.`,
			pl: 'Przyjrzyjmy się produkcji. Na następnym obrazie przyjrzysz się potencjalnym problemom dotyczącym higieny.',
			vn: 'Hãy xem xét kỹ hơn về quá trình sản xuất. Trên hình ảnh tiếp theo, bạn sẽ quan sát những yếu tố có thể gây ra vấn đề về vệ sinh.'
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production',
		layout: 's1-m2-production-hall',
		text: {
			da: `Tryk på de 5 fejl.<br /><br />Tryk OK når du mener, du har dem alle.`,
			en: `Click on the 5 issues.<br /><br />Click OK when you are ready or if you can't find them all. `,
			pl: 'Kliknij 5 problemów.<br /><br />Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.',
			vn: 'Nhấp vào 5 vấn đề. Nhấp OK khi bạn sẵn sàng hoặc nếu bạn không thể tìm thấy tất cả vấn đề.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove-piece', // Stykke fra handske på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'knife', // knife on floor
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'person', // person without apron
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tub', // overfilled tub
			}),

		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-water-drip',
		text: {
			da: `Hvis der drypper fx kondensvand ned fra rør, så skal produkterne fjernes. Hvorfor?`,
			en: `If condensed water is dripping from pipes, you have to remove the products. Why is that?`,
			pl: 'Jeśli z rur kapie skondensowana woda, musisz usunąć produkty. Dlaczego? ',
			vn: 'Nếu nước ngưng tụ nhỏ giọt từ đường ống, bạn phải loại bỏ sản phẩm. Tại sao lại vậy?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate product that follows`,
					pl: 'Woda może doprowadzić do skażenia produktów',
					vn: 'Điều đó có thể làm ô nhiễm sản phẩm xuất ra sau đó'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Produkterne må gerne blive på båndet`,
					en: `The products can in fact stay on the belt`,
					pl: 'Produkty mogą zostać na taśmie',
					vn: 'Trên thực tế, các sản phẩm vẫn có thể nằm trên băng chuyền'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Der er risiko for at forbrugerne bliver syge`,
					en: `There's a risk of consumers getting sick`,
					pl: 'Istnieje ryzyko, że konsumenci zachorują',
					vn: 'Có nguy cơ người tiêu dùng bị bệnh'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kondens tiltrækker skadedyr`,
					en: `Condensed water can attract pests`,
					pl: 'Skondensowana woda może przyciągać szkodniki',
					vn: 'Nước đọng có thể thu hút sâu bệnh'
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-ask-master',
		text: {
			da: `Du er tæt på produktionen hver dag, og derfor lægger du garanteret mærke til ting, som andre overser. Og hvis du er i tvivl, er det altid bedre at spørge en mester.`,
			en: `You are close to our products every day, and you most likely notice things that others overlook. And if you're in doubt about anything, ask a manager.`,
			pl: 'Każdego dnia masz bliski kontakt z naszymi produktami i zapewne zauważasz rzeczy, które inni pomijają. W razie wątpliwości zapytaj kierownika.',
			vn: 'Bạn tiếp xúc gần với sản phẩm của chúng ta mỗi ngày và bạn có thể nhận thức những điều mà người khác có thể bỏ qua. Ngoài ra, nếu bạn có bất kỳ sự nghi ngờ nào, hãy hỏi một người quản lý.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-contamination',
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der.`,
			en: `Now, let's look at contamination. Contamination is when things get into the products that aren't supposed to be there.`,
			pl: 'Teraz omówmy kwestię skażenia. Skażenie ma miejsce, gdy do produktów przedostają się rzeczy, których tam być nie powinno.',
			vn: 'Bây giờ, hãy xem xét về việc nhiễm bẩn. Nhiễm bẩn là khi những thứ không nên có trong sản phẩm lại xâm nhập được vào.'
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-broken-lamp',
		text: {
			da: `Du finder rester af en blå indlægspose i et kar med kød. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `You find a piece of a blue inlay bag in a tub of meat. What should you do? Place the following actions in the right order.`,
			pl: 'Znajdujesz w pojemniku z mięsem niebieską torbę. Co należy zrobić? Umieść działania we właściwej kolejności.',
			vn: 'Bạn phát hiện một miếng túi lót màu xanh trong một thùng thịt. Bạn nên làm gì? Sắp xếp các hành động sau đây theo đúng thứ tự.'
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Stop produktionen`,
					en: `Stop the production`,
					pl: 'Wstrzymać produkcję',
					vn: 'Ngưng việc sản xuất'
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tilkald mester/kvalitet`,
					en: `Call for manager/quality`,
					pl: 'Wezwać kierownika / osobę odpowiedzialną za jakość',
					vn: 'Gọi quản lý/quản lý chất lượng'
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Afvent frigivelse fra mester/kvalitet`,
					en: `Wait for release from manager/quality`,
					pl: 'Poczekać na zielone światło od kierownika/jakości',
					vn: 'Đợi thông báo từ quản lý/quản lý chất lượng'
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`,
					pl: 'Kontyuować pracę',
					vn: 'Tiếp tục công việc'
				}, 
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-product-contamination',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`,
			pl: 'Nasze produkty mogą ulec skażeniu na różne sposoby. Dlaczego skażenie jest problemem?',
			vn: 'Sản phẩm của chúng ta có thể bị nhiễm bẩn theo nhiều cách khác nhau. Tại sao nhiễm bẩn lại là một vấn đề?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`,
					pl: 'Skażenie może być niebezpieczne',
					vn: 'Nhiễm bẩn dẫn đến nguy hiểm'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can ruin the taste`,
					pl: 'Skażenie może zepsuć smak produktu',
					vn: 'Nhiễm bẩn có thể làm hỏng mùi vị sản phẩm'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can cause unhappy customers`,
					pl: 'Skażenie może skutkować niezadowoleniem klientów',
					vn: 'Nhiễm bẩn có thể khiến khách hàng không hài lòng'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`,
					pl: 'Skażenie skutkuje większą emisją CO2',
					vn: 'Nhiễm bẩn thải ra nhiều CO2 hơn'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Forurening er kun et problem, hvis mesteren ser det`,
					en: `Contamination is only a problem if a manager sees it`,
					pl: 'Skażenie jest problemem tylko, jeśli kierownik je zauważy',
					vn: 'Ô nhiễm sẽ chỉ trở thành vấn đề khi người quản lý phát hiện ra mà thôi'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`,
					pl: 'Skażenie może prowadzić do zabrudzenia naszych ubrań',
					vn: 'Nó có thể làm bẩn quần áo của chúng ta'
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-allergies',
		subtype: 'images',
		layout: 's1-m2-allergies',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Klik på fire allergener.`,
			en: `Contamination can be a problem for people with allergies. Click on the four allergens.`,
			pl: 'Skażenie może być problemem dla osób z alergiami. Kliknij cztery alergeny.',
			vn: 'Ô nhiễm có thể là một vấn đề đối với những người bị dị ứng. Bấm vào để xem bốn chất gây dị ứng.'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Nuts
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Flour
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // strawberry 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // apple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // pineapple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // Milk
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, isCorrect: true // seeds and sennep glass
			}),
		]
	}),


	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-hygiene-quality',
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and thus high quality begins with you. See if you can spot the issues on the next image.`,
			pl: 'Dobra higiena, a co za tym idzie – wysoka jakość – zaczyna się od ciebie. Spróbuj znaleźć problemy na następnym obrazie.',
			vn: 'Vệ sinh tốt và tiếp đến là chất lượng cao sẽ bắt đầu từ bạn. Hãy xem xét xem bạn có thể nhận diện vấn đề gì trong hình ảnh tiếp theo.'
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-2',
		layout: 's1-m2-production-hall-2',
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du mener, du har fundet dem alle.`,
			en: `Click on the 4 issues.<br /><br />Click OK when you are ready or if you can't find them all. `,
			pl: 'Kliknij 4 problemy. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.',
			vn: 'Nhấp vào 4 vấn đề. Nhấp OK khi bạn sẵn sàng hoặc nếu bạn không thể tìm thấy tất cả vấn đề.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'screw', // Skrue eller møtrik på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'soap', // Sæbeskum på transportbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hammer', // Hammer på gulvet TODO -> slagtekniv
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-pests',
		text: {
			da: `Et af de helt store problemer i forhold til hygiejne er, hvis der er skadedyr.`,
			en: `Pests can be a huge problem when it comes to hygiene. `,
			pl: 'Szkodniki mogą być poważnym problemem z punktu widzenia higieny.',
			vn: 'Sinh vật gây hại có thể là một vấn đề lớn khi nói đến vệ sinh.'
		}
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-mouse',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: `You spot a mouse on the floor in production. What should you do?`,
			pl: 'Widzisz mysz na podłodze zakładu produkcyjnego. Co należy zrobić?',
			vn: 'Bạn phát hiện một con chuột trên sàn nhà trong khu vực sản xuất. Bạn nên làm gì?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				isCorrect: true,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on it while I call for help`,
					pl: 'Mieć oko na mysz i wezwać pomoc',
					vn: 'Tôi cố gắng để mắt đến con chuột trong khi gọi trợ giúp'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`,
					en: `Try to catch the mouse and then call for help if I don't succeed`,
					pl: 'Spróbować złapać mysz, a w razie niepowodzenia wezwać pomoc',
					vn: 'Cố gắng bắt chuột rồi gọi trợ giúp nếu không thành công'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell my immediate manager at the end of work`,
					pl: 'Powiedzieć bezpośredniemu przełożonemu na koniec pracy',
					vn: 'Trao đổi với người quản lý trực tiếp sau khi kết thúc công việc'
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-delivery',
		text: {
			da: `Vi mangler en sidste ting. Den handler om levering til fragtmænd.`,
			en: `The final task is about deliveries to freighters.`,
			pl: 'Ostatnie zadanie dotyczy dostaw na frachtowce.',
			vn: 'Nhiệm vụ cuối cùng liên quan đến việc giao hàng cho tàu vận chuyển.'
		}
	}),	
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-delivery',
		layout: 's1-m2-delivery',
		text: {
			da: `Når du skal levere til fragtmænd er der især to ting som er vigtig at tjekke. Klik på de to fejl på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem.`,
			en: `When making a delivery to a freighter, two things are especially important to check. Click on the two problems in the image. Click OK when you are ready or if you can't spot them both.`,
			pl: 'Dostarczając produkty na frachtowce, należy sprawdzić dwie ważne kwestie. Kliknij dwa problemy widoczne na obrazie. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć obu problemów.',
			vn: 'Khi giao hàng cho một tàu vận chuyển, có hai điều đặc biệt quan trọng cần kiểm tra. Nhấp vào hai vấn đề trong hình ảnh. Nhấp OK khi bạn sẵn sàng hoặc nếu bạn không thể nhận diện cả hai vấn đề.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'thermometer',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Nemlig! Bilen må ikke være for varm. Tjek altid at bilen har den rette temperatur.`,
								en: `Exactly! The van must not be too hot. Always check if it has the right temperature.`,
								pl: 'Dokładnie! W ciężarówce nie może być zbyt gorąco. Zawsze sprawdzaj, czy panuje w niej odpowiednia temperatura.',
								vn: 'Chính xác! Xe tải không được quá nóng. Luôn kiểm tra xem xe tải có ở mức nhiệt độ phù hợp hay không.'
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirt'
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-finish',
		text: {
			da: `Sådan, du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med hygiejnetræningen for denne gang. Hvis ikke, så må du tage det her modul en gang til.`,
			en: `That's it, you are now through the second module. If you have three stars, you have completed the hygiene training. If not, you have to retake the module.`,
			pl: 'To koniec drugiego modułu. Jeśli masz trzy gwiazdki, ukończyłeś(-aś) szkolenie z zasad higieny. Jeśli nie, musisz przejść moduł ponownie.',
			vn: 'Chúc mừng, bạn đã hoàn thành mô-đun thứ hai. Nếu bạn đạt được ba ngôi sao, bạn đã hoàn thành khóa đào tạo vệ sinh. Nếu không, bạn phải thực hiện lại mô-đun này.'
		}
	})
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};