/* Always loaded */
const basicImagesData = [
	'backgrounds/delika.svg',
	'backgrounds/delika-sunrise-1.svg',
	'backgrounds/delika-sunrise-2.svg',
	'backgrounds/delika-sunrise-3.svg',
	'backgrounds/delika-sunrise-4.svg',
	'backgrounds/delika-sunrise-5.svg',
	'icons/icon-cookies.svg',
	'icons/icon-loading.svg',
	'icons/icon-loading-white.svg',
	'icons/icon-language.svg',
	'icons/icon-logout.svg',
	'languages/da.svg',
	'languages/en.svg',
	'languages/pl.svg',
	'languages/de.svg',
	'languages/vn.svg',
	'logo-cgl.svg',
	'logo-delika-red.svg',
	'logo-delika-white.svg',
];

/* Loaded if logged in as player */
const basicGameImagesData = [
	'backgrounds/floor-grid.svg',
	'backgrounds/dressing-room.svg',
	'backgrounds/lamps.svg',
	'backgrounds/lockers.svg',
	'backgrounds/hygiene-lock.svg',
	'backgrounds/floor-pattern.svg',
	'backgrounds/processing.svg',
	'backgrounds/production-room-a.svg',
	'backgrounds/production-room-b.svg',
	'backgrounds/wall-grid.svg',
	'backgrounds/frames/frame-shade-square-sides.svg',
	'backgrounds/frames/frame-shade-square-top-bottom.svg',
	'backgrounds/frames/frame-shade.svg',
	'characters/manager-1.svg',
	'characters/manager-1-blinking.svg',
	'icons/icon-audio-pause-green.svg',
	'icons/icon-audio-pause-red.svg',
	'icons/icon-audio-pause.svg',
	'icons/icon-audio-play-green.svg',
	'icons/icon-audio-play-red.svg',
	'icons/icon-audio-play.svg',
	'icons/icon-checkmark.svg',
	'icons/icon-corrrect.svg',
	'icons/icon-drag.svg',
	'icons/icon-home.svg',
	'icons/icon-next.svg',
	'icons/icon-star-filled.svg',
	'icons/icon-star.svg',
	'icons/icon-wrong.svg',
	'speech-bubble-feedback.svg',
	'speech-bubble-instructions.svg',
	'text/private-da.svg',
	'text/private-de.svg',
	'text/private-en.svg',
	'text/private-pl.svg',
	'text/private-vn.svg',
	'text/work-da.svg',
	'text/work-de.svg',
	'text/work-en.svg',
	'text/work-pl.svg',
	'text/work-vn.svg',
];

/* Loaded if logged in as facilitator/admin */
const facilitatorImagesData = [
	'icons/icon-arrow-down.svg',
	'icons/icon-cross-white.svg',
	'icons/icon-sort-asc.svg',
	'icons/icon-sort-desc.svg',
	'logo-delika-white.svg'
];

const imagesData = {
	'basic': basicImagesData,
	'basic-game': basicGameImagesData,
	'facilitator': facilitatorImagesData,
	's1-m1': require('data/images/s1-m1').moduleImages,
	's1-m2': require('data/images/s1-m2').moduleImages,
	's1-m3': require('data/images/s1-m3').moduleImages,
	's1-m4': require('data/images/s1-m4').moduleImages,
};

export {
	imagesData
};
