const scenariosData = [
	{
		id: 'scenario-1',
		title: {
			da: 'Scenarie 1',
			en: 'Scenario 1',
		},
		languageIds: ['da', 'en', 'pl', 'vn'],
		modulesData: require('data/scenarios/scenario-1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-1/modules-data').moduleGroups,
	}
];

export {
	scenariosData
};