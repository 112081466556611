const languagesData = [
	{
		id: 'da',
		title: {
			da: 'Dansk', 
			en: 'Dansk', // language names are always in their own language
			de: 'Dansk',
			pl: 'Dansk'
		},
		localization: 'da-DA'
	},
	{
		id: 'en',
		title: {
			da: 'English',
			en: 'English',
			de: 'English',
			pl: 'English'
		},
		localization: 'en-GB'
	},
	{
		id: 'pl',
		title: {
			da: 'Polski',
			en: 'Polski',
			de: 'Polski',
			pl: 'Polski'
		},
		localization: 'pl-PL'
	},
	{
		id: 'vn',
		title: {
			da: 'Tiếng Việt',
			en: 'Tiếng Việt',
			de: 'Tiếng Việt',
			pl: 'Tiếng Việt'
		},
		localization: 'vn-VN'
	}
];

export {languagesData};