import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ModulesOverview from './modules-overview/modules-overview';
import ModuleController from './module/module-controller';
import './game.scss';

const Game = (props) => {
	const {
		languageId, 
		userData, 
		gameData, 
		scrollToTop, 
		handleGoToPage, 
		updateUser, 
		resetPlayerGameData, 
		saveSurveyResponse,
		handleLogout
	} = props;

	/* Game page */
	const [navigation, setNavigation] = useState({pageId: 'modules-overview', moduleId: null});

	/**
	 * Go to game page
	 * @param {string} pageId
	 * @param {string} moduleId
	 */
	const handleGoToGamePage = (pageId, moduleId = null) => {
		setNavigation({pageId, moduleId});
	};

	/* Game page component */
	let GamePageComponent = ModulesOverview;
	if (navigation.pageId === 'module' && navigation.moduleId) GamePageComponent = ModuleController;

	return (
		<div className={'Game ' + navigation.pageId}>
			<GamePageComponent 
				languageId={languageId}
				moduleId={navigation.moduleId}
				userData={userData}
				gameData={gameData}
				scrollToTop={scrollToTop}
				handleGoToGamePage={handleGoToGamePage}
				handleGoToPage={handleGoToPage}
				updateUser={updateUser}
				resetPlayerGameData={resetPlayerGameData}
				saveSurveyResponse={saveSurveyResponse}
				handleLogout={handleLogout}
			/>
		</div>
	);
};

Game.propTypes = {
	languageId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	resetPlayerGameData: PropTypes.func.isRequired,
	saveSurveyResponse: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Game;
