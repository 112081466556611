import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getAllSurveysOfScenarios} from 'helpers/survey-helper';
import {getText} from 'helpers/language-helper';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import Select from 'components/ui/select/select';
import SurveyResults from 'components/ui/survey-results/survey-results';
import './admin-surveys-popup.scss';

const AdminSurveysPopup = ({languageId, surveyResponses, togglePopup}) => {
	/* Get scenario options */
	const scenarioOptions = [{id: 'all', title: adminUiTexts.surveyPopup.allScenarios}];
	scenariosData.forEach((scenario) => {
		scenarioOptions.push({id: scenario.id, title: scenario.title});
	});

	/* Selected scenario id */
	const [scenarioId, setScenarioId] = useState('all');

	/* Selected survey id */
	const [surveyId, setSurveyId] = useState(null);

	/* Get all surveys in selected scenario (or all scenarios), fix ids */
	const allScenarioIds = scenariosData.map((s) => {return s.id;});
	let surveysData = getAllSurveysOfScenarios(allScenarioIds);
	if (scenarioId !== 'all') {
		surveysData = getAllSurveysOfScenarios([scenarioId]);
	}
	surveysData.forEach((surveyData) => {
		surveyData.id = surveyData.taskId;
	});

	/* Get default survey id */
	const defaultSurveyId = surveysData.length > 0 ? surveysData[0].id : null;

	return (
		<div className="AdminSurveysPopup" onClick={() => {togglePopup(null);}}>
			<div className="AdminSurveysPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="AdminSurveysPopup-header">
					<div className="AdminSurveysPopup-title">
						<span>{getText(facilitatorUiTexts.surveys, languageId)}</span>
					</div>
					<div
						className="AdminSurveysPopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>
				</div>

				{/* Popup body */}
				<div className="AdminSurveysPopup-body">
					<div className="AdminSurveysPopup-selectScenarioAndSurvey">
						{/* Select scenario */}
						<div className="AdminSurveysPopup-selectScenario">
							<Select 
								isDisabled={false}
								type="scenario"
								languageId={languageId}
								defaultId={'all'}
								selectedId={scenarioId}
								options={scenarioOptions}
								onSelect={setScenarioId}
							/>
						</div>
						{/* Select survey */}
						{defaultSurveyId && <div className="AdminSurveysPopup-selectSurvey">
							<Select 
								isDisabled={false}
								type="scenario"
								languageId={languageId}
								defaultId={defaultSurveyId}
								selectedId={surveyId}
								options={surveysData}
								onSelect={setSurveyId}
							/>
						</div>}
					</div>

					{/* Survey results */}
					<div className="AdminSurveysPopup-result">
						<SurveyResults 
							languageId={languageId}
							surveyId={surveyId ? surveyId : defaultSurveyId}
							surveysData={surveysData}
							surveysResponses={surveyResponses}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminSurveysPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	surveyResponses: PropTypes.array.isRequired,
	togglePopup: PropTypes.func.isRequired,
};

export default AdminSurveysPopup;
