const taskTemplates = require('data/templates/task-templates');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'sunrise-1',
		text: {
			da: `Velkommen til hygiejnetræningen. Jeg er din manager og hjælper dig gennem spillet.`,
			en: `Welcome to the hygiene training. I'm your co-worker, and I'll help you through the game.`,
			pl: 'Witaj na szkoleniu z higieny. Jestem twoim współpracownikiem i pomogę ci podczas gry.',
			vn: 'Chào mừng đến với khóa đào tạo vệ sinh. Tôi là đồng nghiệp của bạn và tôi sẽ giúp đỡ bạn xuyên suốt trò chơi này.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-points-errors',
		showProgressBar: false,
		background: 'sunrise-2',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: 'W grze otrzymujesz punkty za wykonywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.',
			vn: 'Trong trò chơi này, bạn sẽ nhận được điểm khi giải quyết các nhiệm vụ. Bạn càng mắc ít lỗi, bạn sẽ nhận được càng nhiều điểm.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-3',
		taskId: 's1-m1-instructions-points-stars',
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`,
			pl: 'W miarę zdobywania punktów otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile masz punktów i gwiazdek.',
			vn: 'Khi bạn nhận điểm, bạn cũng sẽ đạt được sao. Ở phía trên màn hình, bạn có thể thấy số điểm và số sao bạn đang có.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-4',
		taskId: 's1-m1-instructions-points-to-progress',
		text: {
			da: `Du skal have tre stjerner for at gå videre til det næste modul.`,
			en: `You need to get three stars to advance to the next module.`,
			pl: 'Potrzebujesz trzech gwiazdek, aby przejść do następnego modułu.',
			vn: 'Bạn cần nhận ba sao để tiến đến mô-đun tiếp theo.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-5',
		taskId: 's1-m1-instructions-first-task',
		text: {
			da: `Første opgave handler om den rigtige påklædning.`,
			en: `The first task is about being dressed properly.`,
			pl: 'Pierwsze zadanie dotyczy odpowiedniego ubioru.',
			vn: 'Nhiệm vụ đầu tiên của bạn là ăn mặc chuẩn chỉ.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hair-and-beard',
		layout: 's1-m1-hair-and-beard',
		subtype: 'images',
		background: 'delika',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde?<br />Tryk på de rigtige billeder.`,
			en: `What is the correct way to use hair- and beardnet?<br />Click on the correct images.`,
			pl: 'Jak powinno się używać siatki do włosów i brody? Kliknij właściwe obrazy.',
			vn: 'Cách sử dụng lưới bao tóc và râu đúng cách là gì? Hãy nhấn vào các hình ảnh thể hiện chính xác'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5		
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		subtype: 'images',
		layout: 's1-m1-jewelry',
		background: 'delika',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What kinds of jewelry are NOT allowed in the production areas?`,
			pl: 'Jakie rodzaje odpowiedzi NIE są dozwolone na obszarze produkcji?',
			vn: 'Loại trang sức nào KHÔNG được cho phép tại khu vực sản xuất?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Glasses
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Nose piercing
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Belly button piercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Wrist watch
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Necklace
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-hygiene-dressing-room',
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede.`,
			en: `Good hygiene begins in the locker room. See if you can spot the issues on the next image.`,
			pl: 'Dobra higiena zaczyna się w przebieralni. Spróbuj znaleźć problemy na następnym obrazie.',
			vn: 'Vệ sinh tốt bắt đầu từ phòng thay đồ. Hãy xem xem bạn có thể nhận diện vấn đề gì trong hình ảnh kế tiếp.'
		},
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room',
		layout: 's1-m1-dressing-room',
		text: {
			da: `Tryk på de 3 ting som er forkerte på billedet.
				<br /><br />Tryk OK når du tror, du har dem alle.`,
			en: `Click on the 3 things that are wrong on the image. 
				<br /><br />Click OK when you are ready or if you can't find them all. `,
			pl: 'Kliknij 3 nieprawidłowości widoczne na obrazie. <br /><br />Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.',
			vn: 'Nhấp vào 3 điểm sai trên hình ảnh. Nhấp OK khi bạn đã sẵn sàng hoặc nếu bạn không thể tìm thấy tất cả điểm sai.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'white-shoes',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair-net',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'apron',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-clothes-dressing-room',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder.`,
			en: `In the locker room there's a locker for your private clothes and shoes. It's important to put things in the right places.`,
			pl: 'W przebieralni jest szafka na twoje osobiste ubrania i buty. Należy umieszczać rzeczy w przeznaczonych na nie miejscach.',
			vn: 'Trong phòng thay đồ có một tủ để đựng quần áo và giày của bạn. Việc đặt đồ đúng chỗ rất quan trọng.'
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-lockers',
		layout: 's1-m1-lockers',
		subtype: 'images',
		text: {
			da: `Hvilke ting skal i skabet til privat tøj?`,
			en: `What goes into the locker for private clothes?`,
			pl: 'Co należy włożyć do szafki na osobiste ubrania?',
			vn: 'Những đồ gì nên đặt vào tủ đựng quần áo cá nhân?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Gul refleksvest,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hvide arbejdssko
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Høreværn
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Termojakke
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Hvid frysejakke med logo
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Halsedisse
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		taskId: 's1-m1-image-lockers',
		layout: 's1-m1-lockers',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-hygiene-lock',
		text: {
			da: `Godt, så er vi ved at være klar til at gå ud i produktionen. Der møder du altid en hygiejnesluse. Lad os lige kigge nærmere på den.`,
			en: `Okay, I think we're about ready to enter production. On your way there, you'll always pass through a hygiene lock. Let's take a closer look at that.`,
			pl: 'Okej, chyba jesteśmy gotowi, aby wejść do obszaru produkcji. Po drodze zawsze przechodzisz przez śluzę higieniczną. Przyjrzyjmy się jej.',
			vn: 'Được rồi, tôi nghĩ chúng ta đã sẵn sàng để bắt đầu quá trình sản xuất. Trên đường đi đến đó, bạn sẽ luôn phải đi qua một khóa vệ sinh. Hãy xem xét kỹ hơn khóa này.'
		},
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-wash-hands',
		background: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In a hygiene lock, you must always wash your hands. What is the correct order to do that in?`,
			pl: 'W śluzie higienicznej zawsze należy umyć ręce. W jakiej kolejności trzeba to zrobić?',
			vn: 'Trong một khóa vệ sinh, bạn luôn phải rửa tay. Thứ tự rửa tay đúng là gì?'
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`,
					pl: 'Umyć ręce mydłem i wodą',
					vn: 'Rửa tay với xà phòng và nước'
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tør hænder med papir eller håndtørrer`,
					en: `Dry hands with paper towels or hand dryer`,
					pl: 'Osusz ręce papierowymi ręcznikami lub suszarką do rąk',
					vn: 'Lau khô tay bằng khăn giấy hoặc máy sấy tay'
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid eventuelt papir i skraldespand`,
					en: `Put any paper towels in the bin`,
					pl: 'Włóż do kosza wszystkie ręczniki papierowe',
					vn: 'Bỏ bất kỳ khăn giấy nào vào thùng rác'
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Brug håndsprit`,
					en: `Use sanitizer`,
					pl: 'Użyć środka dezynfekującego',
					vn: 'Sử dụng chất khử trùng'
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'hygiene-lock',
		text: {
			da: `Håndhygiejne handler også om neglene. 
				<br />Tryk på de billeder, som er forkerte.`,
			en: `The nails are an important part of hand hygiene. 
				<br />Click the incorrect images.`,
			pl: 'Paznokcie są ważną częścią higieny rąk. <br />Kliknij niewłaściwe obrazy.',
			vn: 'Móng tay là một phần quan trọng của vệ sinh tay. <br />Hãy nhấp vào những hình ảnh không đúng.'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true	
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-when-wash-hands',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		background: 'hygiene-lock',
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?
				<br /><br />Vælg 1 - og husk at læse alle mulighederne:`,
			en: `After which situations must you remember to wash your hands? 
				<br /><br />Choose 1 - and remember to read all the options:`,
			pl: 'Po jakich sytuacjach należy pamiętać o umyciu rąk? <br /><br />Wybierz 1 odpowiedź i pamiętaj, aby przeczytać wszystkie opcje:',
			vn: 'Sau những tình huống nào bạn phải nhớ rửa tay?<br /><br />Chọn 1 - và hãy nhớ đọc tất cả các lựa chọn:'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du passerer en hygiejnesluse`,
					en: `When passing a hygiene lock`,
					pl: 'Podczas przechodzenia przez śluzę higieniczną',
					vn: 'Khi đi qua khóa vệ sinh'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`,
					en: `After breaks`,
					pl: 'Po przerwach',
					vn: 'Sau giờ nghỉ'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Efter at binde snørebånd`,
					en: `After tying my shoelaces`,
					pl: 'Po zawiązaniu sznurowadeł',
					vn: 'Sau khi buộc dây giày của tôi'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Når du har rørt ved din mund og næse`,
					en: `After touching my mouth or nose`,
					pl: 'Po dotknięciu moich ust lub nosa',
					vn: 'Sau khi chạm vào miệng hoặc mũi của tôi'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Efter brug af stiger`,
					en: `After using ladders`,
					pl: 'Po używaniu drabin',
					vn: 'Sau khi sử dụng thang'
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all of the situations above`,
					pl: 'We wszystkich powyższych sytuacjach',
					vn: 'Trong tất cả các tình huống trên'
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-finish',
		background: 'hygiene-lock',
		text: {
			da: `Sådan, du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til.`,
			en: `That's it, you've completed the first module. If you have three stars, you can continue to the next module. If not, you'll have to complete this module once more.`,
			pl: 'To koniec pierwszego modułu. Jeśli masz trzy gwiazdki, możesz przejść do następnego modułu. Jeśli nie, musisz ukończyć ten moduł ponownie.',
			vn: 'Chúc mừng, bạn đã hoàn thành mô-đun đầu tiên. Nếu bạn đạt được ba ngôi sao, bạn có thể tiếp tục đến mô-đun tiếp theo. Nếu không, bạn sẽ phải hoàn thành mô-đun này một lần nữa.'
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};