const moduleImages = [
	'modules/tasks/multiple-choice/s1-m2-allergies/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-5.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-6.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-7.svg',
	
	'modules/tasks/spot-errors/s1-m2-delivery/background.svg',
	'modules/tasks/spot-errors/s1-m2-delivery/dirt.svg',
	'modules/tasks/spot-errors/s1-m2-production-hall/background.svg',
];

export {
	moduleImages
};