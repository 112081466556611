// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const playerUiTexts = {
	/* Login */
	workerId: {
		da: 'Er dette dit medarbejdernummer?',
		en: 'Is this your employee number?',
		pl: 'Czy to Twój numer pracownika?',
		de: 'Ist das Ihre Mitarbeiternummer?',
		vn: 'Đây có phải là mã số nhân viên của bạn không?'
	},
	yes: {
		da: 'Ja',
		en: 'Yes',
		pl: 'Tak',
		de: 'Ja',
		vn: 'Đúng'
	},
	no: {
		da: 'Nej',
		en: 'No',
		pl: 'Nie',
		de: 'Nein',
		vn: 'Không'
	},

	/* Lobby */
	noGame: {
		da: 'Der findes ikke noget spil under denne URL',
		en: 'There is no game connected to this URL',
		pl: 'Z tym adresem URL nie jest powiązana żadna gra',
		de: 'Mit dieser URL ist kein Spiel verbunden',
		vn: 'Không có trò chơi nào được kết nối với URL này'
	},
	playerDiffGameConfirmId: {
		da: `Det ser ud til dit medarbejdernummer %userId% hører til på %gameName%. 
			<br /><br />Er du sikker på, det er dit medarbejdernummer?`,
		en: `It looks like you employee id %userId% is connected to %gameName%.
			<br /><br />Are you sure it is your employee id?`,
		pl: `Wygląda na to, że Twój numer pracownika %userId% jest połączony z %gameName%. 
			<br /><br />Czy na pewno jest to Twój numer pracownika?`,
		de: `Offenbar ist Ihre Mitarbeiter-ID %userId% mit %gameName% verbunden.
			<br /><br />Sind Sie sicher, dass es sich um Ihre Mitarbeiter-ID handelt?`,
		vn: 'Có vẻ như id nhân viên của bạn %userId% được kết nối với %gameName%.<br /><br />Bạn có chắc đó là id nhân viên của bạn không?'
	},	
	noLogOut : {
		da: 'Nej, log ud',
		en: 'No, log out',
		pl: 'Nie, wyloguj się',
		de: 'Nein, melden Sie sich ab',
		vn: 'Không, đăng xuất'
	},
	playerDiffGame: {
		da: `Du er allerede i gang med %gameName%.
			<br /><br />Du kan kun være i gang med ét spil ad gangen.`,
		en: `You are currently connected to %gameName%.
			<br /><br />You can only be connected to one game at a time.`,
		pl: `Jesteś obecnie połączony(-a) z %gameName%. 
			<br /><br />W danym momencie możesz być połączony(-a) tylko z jedną grą.`,
		de: `Sie sind derzeit mit %gameName% verbunden.
			<br /><br />Sie können jeweils nur mit einem Spiel verbunden sein.`,
		vn: 'Bạn hiện đang kết nối với %gameName%.<br /><br />Bạn chỉ có thể kết nối với một trò chơi tại một thời điểm.'
	},
	gameDeleted: {
		da: '??? (slettet)',
		en: '??? (deleted)',
		pl: '??? (usunięte)',
		de: '??? (gelöscht)',
		vn: '??? (đã xóa)'
	},
	goToGameBtn: {
		da: 'Gå til %gameName%',
		en: 'Go to %gameName%',
		pl: 'Przejdź do %gameName%',
		de: 'Gehe zu %gameName%',
		vn: 'Đi đến %gameName%'
	},
	switchGameBtn: {
		da: 'Skift til %gameName%',
		en: 'Switch to %gameName%',
		pl: 'Przełącz na %gameName%',
		de: 'Wechseln Sie zu %gameName%',
		vn: 'Chuyển sang %gameName%'
	},
	switchGameWarning: {
		da: `Du kan kun være i gang med ét spil ad gangen.
			<br /><br />Hvis du skifter til %gameName2% vil al din data for %gameName1% blive slettet.
			<br /><br />Er du sikker på, du vil skifte til %gameName2%?`,
		en: `You can only be connected to one game at a time.
			<br /><br />If you switch to %gameName2% all your progress in %gameName1% will be lost.
			<br /><br />Are you sure you want to switch to %gameName2%?`,
		pl: `Możesz być połączony(-a) tylko z jedną grą na raz.
			<br /><br />Jeśli przełączysz się na %gameName2%, wszystkie Twoje postępy w %gameName1% zostaną utracone.
			<br /><br />Czy na pewno chcesz przełączyć się na %gameName2%?`,
		de: `Sie können jeweils nur mit einem Spiel verbunden sein.
			<br /><br />Wenn Sie zu %gameName2% wechseln, gehen alle Ihre Fortschritte in %gameName1% verloren.
			<br /><br />Sind Sie sicher, dass Sie zu %gameName2% wechseln möchten?e you want to switch to %gameName2%?`,
		vn: 'Bạn chỉ có thể kết nối với một trò chơi tại một thời điểm.<br /><br />Nếu bạn chuyển sang %gameName2%, tất cả tiến trình của bạn trong %gameName1% sẽ bị mất.<br /><br />Bạn có chắc chắn muốn chuyển sang %gameName2% không?'
	},
	cancelBtn: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren',
		vn: 'Hủy bỏ'
	},
	confirmBtn: {
		da: 'Ja, skift spil',
		en: 'Yes, switch game',
		pl: 'Tak, zmień grę',
		de: 'Ja, Spiel wechseln',
		vn: 'Có, chuyển trò chơi'
	},

	/* Choose language */
	chooseLanguage: {
		da: 'Vælg sprog / choose language',
		en: 'Choose language / vælg sprog',
		pl: 'Wybierz język / choose language',
		de: 'Sprache wählen / choose language',
		vn: 'Chọn ngôn ngữ / choose language'
	},

	/* Module overview */
	reset: {
		da: 'Reset',
		en: 'Reset',
		pl: 'Zresetuj',
		de: 'Zurücksetzen',
		vn: 'Đặt lại'
	},

	moduleOverview: {
		da: 'Moduloversigt',
		en: 'Module overview',
		pl: 'Przegląd modułów',
		de: 'Modul Übersicht',
		vn: 'Tổng quan về mô-đun'
	},
	employeeNumber: {
		da: 'Medarbejder-nr.',
		en: 'Employee no.',
		pl: 'Numer pracownika',
		de: 'Mitarbeiter-Nr.',
		vn: 'Mã số nhân viên'
	},

	/* Module - general */
	ok: {
		da: 'OK',
		en: 'OK',
		pl: 'OK',
		de: 'OK',
		vn: 'OK'
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
		pl: 'Wybierz',
		de: 'Wähle',
		vn: 'Chọn'
	},
	option: {
		da: 'svar',
		en: 'option',
		pl: 'Opcja',
		de: 'Möglichkeit',
		vn: 'tùy chọn'
	},
	options: {
		da: 'svar',
		en: 'options',
		pl: 'Opcje',
		de: 'Optionen',
		vn: 'các tùy chọn'
	},
	finishBtn: {
		da: 'Afslut',
		en: 'Finish',
		pl: 'Zakończ',
		de: 'Beenden',
		vn: 'Hoàn thành'
	},

	/* Module - results */
	results: {
		da: 'resultat',
		en: 'results',
		pl: 'wyniki',
		de: 'Ergebnisse',
		vn: 'kết quả'
	},
	stars: {
		da: 'Stjerner',
		en: 'Stars',
		pl: 'Gwiazdki',
		de: 'Sterne',
		vn: 'Ngôi sao'
	},
	points: {
		da: 'Point',
		en: 'Points',
		pl: 'Punkty',
		de: 'Punkte',
		vn: 'Điểm'
	},
	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
		pl: 'Spędzony czas',
		de: 'Zeitaufwand',
		vn: 'Thời gian đã dùng'
	},
	avgErrors: {
		da: 'Antal fejl per opgave (gennemsnit)',
		en: 'Average mistakes per task',
		pl: 'Średnia liczba błędów na zadanie',
		de: 'Durchschnittliche Fehler pro Aufgabe',
		vn: 'Số lỗi trung bình trên mỗi nhiệm vụ'
	},
	noErrorsTasks: {
		da: 'Antal opgaver løst uden fejl',
		en: 'Number of tasks solved without mistakes',
		pl: 'Liczba zadań rozwiązanych bez błędów',
		de: 'Anzahl der fehlerfrei gelösten Aufgaben',
		vn: 'Số nhiệm vụ được giải quyết mà không mắc lỗi'
	},
	backToModuleOverview: {
		da: 'Tilbage til moduloversigt',
		en: 'Back to module overview',
		pl: 'Powrót do przeglądu modułów',
		de: 'Zurück zur Modulübersicht',
		vn: 'Quay lại tổng quan về mô-đun'
	},
	tryAgain: {
		da: 'Prøv igen',
		en: 'Try again',
		pl: 'Spróbuj ponownie',
		de: 'Versuchen Sie es erneut',
		vn: 'Thử lại'
	},
	confirmTryAgainPopup: {
		title: {
			da: 'Prøv igen',
			en: 'Try again'	,
			pl: 'Spróbuj ponownie',
			de: 'Versuche es erneut',
			vn: 'Thử lại'
		},
		text: {
			da: 'Vil du prøve at slå din egen score? Du kan nulstille modulet og spille igen.',
			en: 'Want to try to beat your own score? You can reset the module and play again.',
			pl: 'Chcesz spróbować pobić swój własny wynik? Możesz zresetować moduł i grać ponownie.',
			de: 'Möchtest Du versuchen, deinen eigenen Punktestand zu übertreffen? Du kannst das Modul zurücksetzen und erneut spielen.',
			vn: 'Bạn muốn thử đánh bại số điểm của chính mình? Bạn có thể đặt lại mô-đun và chơi lại.'
		},
		resetBtn: {
			da: 'Nulstil',
			en: 'Reset',
			pl: 'Zresetuj',
			de: 'Zurücksetzen',
			vn: 'Đặt lại'
		},
		cancelBtn: {
			da: 'Afbryd',
			en: 'Cancel',
			pl: 'Anuluj',
			de: 'Stornieren',
			vn: 'Hủy bỏ'
		}
	},

	/* Module - survey task */
	surveyChooseXOptions: {
		da: 'Vælg %X%',
		en: 'Choose %X%',
		pl: 'Wybierz %X%',
		vn: 'Chọn %X%'
	},
	surveyChooseMaxXOptions: {
		da: 'Vælg op til %X%',
		en: 'Choose up to %X%',
		pl: 'Wybierz do %X%',
		vn: 'Chọn tối đa %X%'
	},

	/* Module - order task */
	orderPopup: {
		title: {
			da: 'Du fik %correctAnswers%/%totalAnswers%.',
			en: `You got %correctAnswers%/%totalAnswers%.`,
			pl: 'Masz %correctAnswers%/%totalAnswers%.',
			de: 'Du hast %correctAnswers%/%totalAnswers% erhalten.',
			vn: 'Bạn có %trueAnswers%/%totalAnswers%.'
		},
		text: {
			da: 'Prøv igen!',
			en: `Try again!`,
			pl: 'Spróbuj ponownie!',
			de: 'Versuche es erneut!',
			vn: 'Thử lại!'
		}
	},

	/* Module - spot errors task */
	spotErrorsPopup: {
		title1: {
			da: 'Ups!',
			en: 'Oops!',
			pl: 'Ups!',
			de: 'Hoppla!',
			vn: 'Ối!'
		},
		text1: {
			da: 'Du fandt kun %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You only %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znaleziono tylko %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Du hast nur %spottedErrors% von %numberOfErrors% Fehlern.',
			vn: 'Bạn chỉ mắc %spottedErrors% / %numberOfErrors% lỗi.'
		},
		title2: {
			da: 'Ikke dårligt!',
			en: 'Not bad!',
			pl: 'Nieźle!',
			de: 'Nicht schlecht!',
			vn: 'Không tệ!'
		},
		text2: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znaleziono %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Du hast %spottedErrors% von %numberOfErrors% Fehlern entdeckt.',
			vn: 'Bạn đã phát hiện %spottedErrors% trong số %numberOfErrors% lỗi.'
		},
		title3: {
			da: 'Godt klaret!',
			en: 'Well done!',
			pl: 'Dobra robota!',
			de: 'Gut gemacht!',
			vn: 'Tốt lắm!'
		},
		text3: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znaleziono %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Sie haben %spottedErrors% von %numberOfErrors% Fehlern entdeckt.',
			vn: 'Bạn đã phát hiện %spottedErrors% trong số %numberOfErrors% lỗi.'
		},
	},

	/* Module - talk to facilitator popup */
	talkToFacilitatorPopup: {
		text: {
			da: 'Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.',
			en: 'You have had too many errors in this module. Talk to your facilitator about how to proceed.',
			pl: 'W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o dalszym postępowaniu.',
			de: 'In diesem Modul sind zu viele Fehler aufgetreten. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.',
			vn: 'Bạn đã mắc quá nhiều lỗi trong mô-đun này. Nói chuyện với người hướng dẫn của bạn về cách tiến hành.'
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			de: 'Ok',
			vn: 'Ok'
		}
	},

	/* Module - locked popup */
	moduleLockedPopup: {
		text: {
			da: `Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.
				<br /><br />Modulet er låst indtil %date%.`,
			en: `You have had too many errors in this module. Talk to your facilitator about how to proceed. 
				<br /><br />This module will be locked until %date%`,
			pl: `W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o dalszym postępowaniu.
				<br /><br />Ten moduł będzie zablokowany do dnia %date%`,
			de: `Du hast in diesem Modul zu viele Fehler gemacht. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.
				<br /><br />Dieses Modul wird bis zum %date% gesperrt.`,
			vn: `Bạn đã mắc quá nhiều lỗi trong mô-đun này. Nói chuyện với người hướng dẫn của bạn về cách tiến hành.
				<br /><br />Mô-đun này sẽ bị khóa cho đến %date%`
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			de: 'Ok',
			vn: 'OK'
		}
	}
};

module.exports = {
	playerUiTexts
};