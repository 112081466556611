// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const cookiesUiTexts = {
	title: {
		da: 'Vi bruger cookies',
		en: 'We use cookies',
		pl: 'Używamy plików cookie',
		de: 'Wir verwenden Cookies',
		vn: 'Chúng tôi sử dụng cookie '
	},
	text: {
		da: `Vi bruger cookies til at forbedre din spiloplevelse. De håndterer din adgang spillet, og spillet fungerer derfor ikke uden.
			<br /><br />Tryk "OK" for at give os samtykke til at bruge cookies.
			<br /><br />Dit samtykke er gyldigt i et år. Du kan til hver en tid trække dit samtykke tilbage ved at klikke på ikonet nede i venstre hjørne.`,
		en: `We use cookies to improve your game experience. They handle your access to the game, and the game will not work without them. 
			<br /><br />Press "OK" to give us your consent to using cookies. 
			<br /><br />Your consent is valid for a year. You can at any time withdraw your consent by clicking on the icon in the bottom left corner. `,
		pl: `Używamy plików cookie, aby poprawić wrażenia z gry. Obsługują Twój dostęp do gry, dlatego bez nich gra nie działa.
			<br /><br />Naciśnij „OK”, aby wyrazić zgodę na używanie plików cookie.                                         
			<br /><br />Twoja zgoda jest ważna przez rok. W każdej chwili możesz wycofać swoją zgodę, klikając ikonę w lewym dolnym rogu.`,
		de: `Wir verwenden Cookies, um Ihr Spielerlebnis zu verbessern. Sie verwalten Ihren Zugriff auf das Spiel und das Spiel wird ohne sie nicht funktionieren.
			<br /><br />Klicken Sie auf „OK“, um uns Ihr Einverständnis zur Verwendung von Cookies zu erteilen.
			<br /><br />Ihre Einwilligung ist ein Jahr gültig. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie auf das Symbol unten links klicken.`,
		vn: 'Chúng tôi sử dụng cookie để cải thiện trải nghiệm trò chơi của bạn. Họ xử lý quyền truy cập của bạn vào trò chơi và trò chơi sẽ không hoạt động nếu không có họ. <br /><br />Nhấn "OK" để đồng ý cho chúng tôi sử dụng cookie. <br /><br />Sự đồng ý của bạn có hiệu lực trong một năm. Bạn có thể rút lại sự đồng ý của mình bất cứ lúc nào bằng cách nhấp vào biểu tượng ở góc dưới bên trái.'
	},
	okBtn: {
		da: 'OK',
		en: 'OK',
		pl: 'OK',
		de: 'OK',
		vn: 'ĐƯỢC RỒI'
	},
	rejectBtn: {
		da: 'Afvis',
		en: 'Reject',
		pl: 'Odrzuć',
		de: 'Ablehnen',
		vn: 'Từ chối'
	}
};

module.exports = {
	cookiesUiTexts
};