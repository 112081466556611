import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyA0amw1Zx1IGGd2MvHi56gqGcvixK2zc6g',
		authDomain: 'cgl-delika-test.firebaseapp.com',
		projectId: 'cgl-delika-test',
		storageBucket: 'cgl-delika-test.appspot.com',
		messagingSenderId: '1094968891829',
		appId: '1:1094968891829:web:11b83a4fd816b18a76e1fc'					
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDOM1BX1n9U4gVLYBvA4NZhrpWxudP3nsM',
		authDomain: 'cgl-delika-production.firebaseapp.com',
		projectId: 'cgl-delika-production',
		storageBucket: 'cgl-delika-production.appspot.com',
		messagingSenderId: '492178840987',
		appId: '1:492178840987:web:fafb4f41b3cf5baa91953e'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;