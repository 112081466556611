// NOTE: remember to update the UI-texts file on the drive, if you update this file */
const loginUiTexts = {
	start: {
		da: 'Start',
		en: 'Start',
		pl: 'Początek',
		de: 'Start',
		vn: 'Bắt đầu'
	},
	login: {
		da: 'Log ind',
		en: 'Log in',
		pl: 'Zaloguj się',
		de: 'Anmeldung',
		vn: 'Đăng nhập'
	},
	logout: {
		da: 'Log ud',
		en: 'Log out',
		pl: 'Wyloguj się',
		de: 'Ausloggen',
		vn: 'Đăng xuất'
	},
	player: {
		da: 'Spiller',
		en: 'Player',
		pl: 'Gracz',
		de: 'Spieler',
		vn: 'Người chơi'
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator',
		pl: 'Moderator',
		de: 'Moderator',
		vn: 'Người hướng dẫn'
	},
	admin: {
		da: 'Administrator',
		en: 'Administrator',
		pl: 'Administrator',
		de: 'Administrator',
		vn: 'Quản trị viên'
	},
	email: {
		da: 'e-mail',
		en: 'e-mail',
		pl: 'e-mail',
		de: 'e-mail',
		vn: 'email'
	},
	password: {
		da: 'password',
		en: 'password',
		pl: 'hasło',
		de: 'Passwort',
		vn: 'mật khẩu'
	},
	passwordRepeat: {
		da: 'gentag password',
		en: 'repeat password',
		pl: 'powtórz hasło',
		de: 'Passwort wiederholen',
		vn: 'lặp lại mật khẩu'
	},
	userId: {
		da: 'medarbejder-nr.',
		en: 'employee no.',
		pl: 'numer pracownika',
		de: 'Mitarbeiter-Nr.',
		vn: 'mã số nhân viên'
	},
	loginWithUserId: {
		da: 'Log ind med medarbejder-nr.',
		en: 'Log in with employee no.',
		pl: 'Zaloguj się za pomocą numeru pracownika',
		de: 'Melden Sie sich mit der Mitarbeiter-Nr. an.',
		vn: 'Đăng nhập bằng mã số nhân viên'
	},
	createNewUser: {
		da: 'Opret ny bruger',
		en: 'Create new user',
		pl: 'Utwórz nowego użytkownika',
		de: 'Neuen Benutzer erstellen',
		vn: 'Tạo người dùng mới'
	},
	forgotPassword: {
		da: 'Glemt dit password?',
		en: 'Forgot your password?',
		pl: 'Zapomniałeś hasła?',
		de: 'Haben Sie Ihr Passwort vergessen?',
		vn: 'Quên mật khẩu?'
	},
	resetPassword: {
		da: 'Nulstil password',
		en: 'Reset tpassword',
		pl: 'Zresetuj hasło',
		de: 'Passwort zurücksetzen',
		vn: 'Đặt lại mật khẩu'
	},
	goToLoginBtn: {
		da: 'Tilbage til login',
		en: 'Back to login',
		pl: 'Powrót do logowania',
		de: 'Zurück zur Anmeldung',
		vn: 'Quay lại đăng nhập'
	},
	createUserBtn: {
		da: 'Opret',
		en: 'Create',
		pl: 'Utwórz',
		de: 'Erstellen',
		vn: 'Tạo'
	},
	reset: {
		da: 'Nulstil',
		en: 'Reset',
		pl: 'Zresetuj',
		de: 'Zurücksetzen',
		vn: 'Đặt lại'
	},
	cancel: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren',
		vn: 'Hủy bỏ'
	},
	cancel2: {
		da: 'Annuller',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren',
		vn: 'Hủy bỏ'
	},
	createUserFeedback: {
		da: 'Din bruger er blevet oprettet. Gå tilbage og log ind for at spille.',
		en: 'Your new user has been created. Go back to log in to play.',
		pl: 'Twój użytkownik został utworzony. Wróć i zaloguj się, aby grać.',
		de: 'Ihr neuer Benutzer wurde erstellt. Gehen Sie zurück, um sich zum Spielen anzumelden.',
		vn: 'Người dùng mới của bạn đã được tạo. Quay lại đăng nhập để chơi.'
	},
	passwordResetFeedback: {
		da: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
		en: 'You have received a link to reset your password in your inbox.',
		pl: 'Otrzymałeś link do zresetowania hasła w swojej skrzynce odbiorczej.',
		de: 'Sie habBạn đã nhận được liên kết để đặt lại mật khẩu trong hộp thư đến của mình.'
	}
};




module.exports = {
	loginUiTexts
};