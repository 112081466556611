import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Audio from 'components/ui/audio/audio';
import './task-intro.scss';

const TaskIntro = (props) => {
	/* Props */
	const {
		text, 
		selectOptionsText,
		image, 
		fileName
	} = props;



	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className="TaskIntro-text">
				{text && renderMarkdown(text)}
				{selectOptionsText && <span>{selectOptionsText}</span>}
			</div>
			{image && <div className="TaskIntro-image" />}
			<div className="TaskIntro-audio">
				<Audio 
					type='task-intro'
					fileName={fileName}
				/>
			</div>
		</div>
	);
};

TaskIntro.propTypes = {
	text: PropTypes.string,
	selectOptionsText: PropTypes.string,
	image: PropTypes.string,
	fileName: PropTypes.string.isRequired
};

export default TaskIntro;
