// NOTE: remember to update the UI-texts file on the drive, if you update this file */
const appConfig = require('config/app.config').default;

const errorUiTexts = {
	unknownError: {
		da: 'Fejl: ukendt fejl.',
		en: 'Error: unknown error.',
		pl: 'Błąd: nieznany błąd.',
		de: 'Fehler: unbekannter Fehler.',
		vn: 'Lỗi: lỗi không xác định.'
	},

	/* Cookies not accepted */
	cookiesNotAccepted: {
		da: `Du skal godkende brug af cookies for at kunne logge ind.`,
		en: 'You have to accept use of cookies to log in.',
		pl: 'Aby się zalogować, musisz zaakceptować użycie plików cookie.',
		de: 'Um sich anzumelden, müssen Sie die Verwendung von Cookies akzeptieren.',
		vn: 'Bạn phải chấp nhận sử dụng cookie để đăng nhập.'
	},

	/* Create user (facilitator) */
	emptyFields: {
		da: 'Du skal udfylde alle felter.',
		en: 'Please fill out all fields.',
		pl: 'Proszę wypełnić wszystkie pola.',
		de: 'Bitte fülle alle Felder aus.',
		vn: 'Hãy điền vào tất cả các lĩnh vực.'
	},
	emailExists: {
		da: 'Der findes allerede en bruger med denne email.',
		en: 'This email is already in use.',
		pl: 'Ten email jest już w użyciu.',
		de: 'Diese E-Mail-Adresse wird schon verwendet.',
		vn: 'Email này đã được sử dụng.'
	},
	invalidPassword: {
		da: 'Dit password skal være på mindst 8 tegn.',
		en: 'Your password has to be at least 8 characters.',
		pl: 'Twoje hasło musi mieć co najmniej 8 znaków.',
		de: 'Ihr Passwort muss mindestens 8 Zeichen lang sein.',
		vn: 'Mật khẩu của bạn phải có ít nhất 8 ký tự.'
	},
	noPasswordMatch: {
		da: 'Password skal være ens i begge felter.',
		en: 'Passwords do not match.',
		pl: 'Hasła nie pasują do siebie.',
		de: 'Passwörter stimmen nicht überein.',
		vn: 'Mất khẩu không hợp lệ.'
	},

	/* Login / reset password (facilitator) */
	emailNotFound: {
		da: 'Der findes ingen bruger med denne email.',
		en: 'There is no user linked to this email.',
		pl: 'Żaden użytkownik nie jest powiązany z tym e-mailem.',
		de: 'Mit dieser E-Mail ist kein Benutzer verknüpft.',
		vn: 'Không có người dùng nào được liên kết với email này.'
	},
	invalidEmail: {
		da: 'Din email er ikke gyldig.',
		en: 'This email is not valid.',
		pl: 'Ten e-mail jest nieprawidłowy.',
		de: 'Diese E-Mail ist ungültig.',
		vn: 'Email này không hợp lệ.'
	},
	invalidEmailPasswordFormat: {
		da: 'Fejl. Check om din email og password er korrekte.',
		en: 'Error. Check if your email and password are correct.',
		pl: 'Błąd. Sprawdź, czy Twój adres e-mail i hasło są poprawne.',
		de: 'Fehler. Überprüfen Sie, ob Ihre E-Mail-Adresse und Ihr Passwort korrekt sind.',
		vn: 'Lỗi. Kiểm tra xem email và mật khẩu của bạn có đúng không.'
	},
	
	/* Login (player) */
	emptyUserId: {
		da: 'Husk at udfylde dit medarbejder-nr.',
		en: 'Please enter your employee no.',
		pl: 'Proszę podać swój numer pracownika.',
		de: 'Bitte geben Sie Ihre Mitarbeiter-Nr. ein.',
		vn: 'Vui lòng nhập số nhân viên của bạn.'
	},
	invalidUserId: {
		da: 'Det indtastede nummer er forkert. Husk det skal være ' + appConfig.employeeIdLength + ' cifre.',
		en: 'Invalid number. Remember it must be ' + appConfig.employeeIdLength + ' digits.',
		pl: 'Nieprawidłowy numer. Pamiętaj, że musi to być ' + appConfig.employeeIdLength + ' cyfr.',
		de: 'Ungültige Nummer. Denken Sie daran, dass es ' + appConfig.employeeIdLength + ' Ziffern sein muss.',
		vn: 'Số không hợp lệ. Hãy nhớ rằng nó phải là các chữ số ' + appConfig.employeeIdLength + '.'
	}
};

module.exports = {
	errorUiTexts
};