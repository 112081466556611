import Instructions from 'components/game/module/engines/instructions/instructions';
import Image from 'components/game/module/engines/image/image';
import Order from 'components/game/module/engines/order/order';
import MultipleChoice from 'components/game/module/engines/multiple-choice/multiple-choice';
import SpotErrors from 'components/game/module/engines/spot-errors/spot-errors';
import Sort from 'components/game/module/engines/sort/sort';
import PaperDoll from 'components/game/module/engines/paper-doll/paper-doll';
import Survey from 'components/game/module/engines/survey/survey';

const moduleComponents = {
	instructions: Instructions,
	image: Image,
	'multiple-choice': MultipleChoice,
	order: Order,
	sort: Sort,
	'spot-errors': SpotErrors,
	'paper-doll': PaperDoll,
	survey: Survey
};

export {
	moduleComponents
};