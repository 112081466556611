import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Background} from 'assets/images/backgrounds/delika.svg';
import {ReactComponent as Sunrise1} from 'assets/images/backgrounds/delika-sunrise-1.svg';
import {ReactComponent as Sunrise2} from 'assets/images/backgrounds/delika-sunrise-2.svg';
import {ReactComponent as Sunrise3} from 'assets/images/backgrounds/delika-sunrise-3.svg';
import {ReactComponent as Sunrise4} from 'assets/images/backgrounds/delika-sunrise-4.svg';
import {ReactComponent as Sunrise5} from 'assets/images/backgrounds/delika-sunrise-5.svg';
import './delika-background.scss';

const DelikaBackground = ({background = null, scenarioId = 'scenario-1'}) => {
	let BackgroundComponent = Background;

	switch (background) {
	case 'sunrise-1': 
		BackgroundComponent = Sunrise1;
		break;
	case  'sunrise-2':
		BackgroundComponent = Sunrise2;
		break;
	case  'sunrise-3':
		BackgroundComponent = Sunrise3;
		break;
	case  'sunrise-4':
		BackgroundComponent = Sunrise4;
		break;
	case  'sunrise-5':
		BackgroundComponent = Sunrise5;
		break;
	default: 
		BackgroundComponent = Background;
	}

	return (
		<div className='DelikaBackground'>
			<BackgroundComponent className={'DelikaBackground-background ' + scenarioId}/>
		</div>
	);
};

DelikaBackground.propTypes = {
	background: PropTypes.string,
	scenarioId: PropTypes.string,
};

export default DelikaBackground;