const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-welcome',
		background: 'sunrise-1',
		text: {
			da: `Velkommen til hygiejnetræningen. Det er det første af to moduler, du skal igennem i dag.`,
			en: `Welcome to first part of the hygiene retraining. This is the first of two modules, you have to complete today.`,
			pl: 'Witaj w pierwszej części ponownego szkolenia z zasad higieny. To jest pierwszy z dwóch modułów, które dziś przejdziesz.',
			vn: 'Chào mừng bạn đến với phần đầu tiên của khóa đào tạo lại về vệ sinh. Đây là mô-đun đầu tiên trong hai mô-đun mà bạn phải hoàn thành hôm nay.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-points',
		background: 'sunrise-2',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: 'W grze otrzymujesz punkty za wykonywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.',
			vn: 'Trong trò chơi này, bạn sẽ nhận được điểm khi giải quyết các nhiệm vụ. Bạn càng mắc ít lỗi, bạn sẽ nhận được càng nhiều điểm.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars',
		background: 'sunrise-3',
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`,
			pl: 'W miarę zdobywania punktów otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile masz punktów i gwiazdek.',
			vn: 'Khi bạn nhận điểm, bạn cũng sẽ đạt được sao. Ở phía trên màn hình, bạn có thể thấy số điểm và số sao bạn đang có.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-points-to-progress',
		background: 'sunrise-4',
		text: {
			da: `Husk du skal have tre stjerner for at gennemføre.`,
			en: `You need to get three stars to advance to the next module.`,
			pl: 'Potrzebujesz trzech gwiazdek, aby przejść do następnego modułu.',
			vn: 'Bạn cần đạt được ba ngôi sao để tiến đến mô-đun tiếp theo.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-first-task',
		text: {
			da: `Vi starter med påklædning. På næste billede skal du trykke på de ting, som er forkerte. Og jeg ved godt at nogle af jer har en anden farve tøj på, det er selvfølgelig ikke en fejl. `,
			en: `We begin with how to dress. On the next image, click on the things that are wrong. And I do know that some of you wear different colours, so that is obviously not a mistake.`,
			pl: 'Zaczniemy od ubioru. Na następnym obrazie kliknij nieprawidłowości. I wiem, że niektórzy noszą ubrania różnych kolorów, więc to oczywiście nie jest błąd.',
			vn: 'Chúng ta sẽ bắt đầu với cách ăn mặc đúng. Trong hình ảnh tiếp theo, hãy nhấp vào những điểm sai. Và tôi biết rằng một số bạn có thể mặc trang phục có màu sắc khác nhau, vì vậy đó rõ ràng không phải là một lỗi.'
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-person',
		layout: 's1-m3-person',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 5 fejl.<br /><br />Tryk OK når du tror, du har fundet dem alle.`,
			en: `Click on the 5 issues.<br /><br />Click OK when you are ready or if you can't find them all. `,
			pl: 'Kliknij 5 problemów.<br /><br />Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.',
			vn: 'Nhấp vào 5 vấn đề. Nhấp OK khi bạn sẵn sàng hoặc nếu bạn không thể tìm thấy tất cả vấn đề.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirt',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'buttons',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'beardnet',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-hand-hygiene',
		background: 'dressing-room',
		text: {
			da: `En af de vigtigste regler er at have god håndhygiejne. Det handler både om at vaske hænder tit, men også om at gøre det ordentligt.`,
			en: `Proper hand hygiene is one of the most important things. That means not only to wash your hands often, but also to do it the right way.`,
			pl: 'Właściwa higiena rąk jest niezwykle ważna. To znaczy, że musisz myć ręce nie tylko często, ale również prawidłowo.',
			vn: 'Vệ sinh tay đúng cách là một trong những điều quan trọng nhất. Điều đó có nghĩa không chỉ là thường xuyên rửa tay mà còn phải rửa tay đúng cách.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-hands',
		layout: 's1-m3-hands',
		background: 'hygiene-lock',
		subtype: 'images',
		text: {
			da: `Der er især to områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Three areas are especially easy to forget, when washing hands. What are they?`,
			pl: 'Podczas mycia rąk szczególnie łatwo zapomnieć o trzech częściach. Jakich?',
			vn: 'Ba vùng thường dễ quên khi rửa tay là gì?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // inside of wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // between fingers
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-when-washing-hands',
		background: 'hygiene-lock',
		text: {
			da: `Hvor tit skal du huske at vaske hænder?`,
			en: `How often must you wash your hands?`,
			pl: 'Jak często należy myć ręce?',
			vn: 'Bạn phải rửa tay bao nhiêu lần?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Hver anden time`,
					en: `Every second hour`,
					pl: 'Co dwie godziny',
					vn: 'Hai giờ một lần'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Når de er blevet forurenet`,
					en: `When they've been contaminated`,
					pl: 'Gdy uległy skażeniu',
					vn: 'Khi tay đã nhiễm bẩn'
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Nemlig! For eksempel hvis du bruger stiger, rører ved dit ansigt eller samler noget op fra gulvet.`,
								en: `Exactly! For instance if you use a ladder, touch your face, or pick something up from the floor.`,
								pl: 'Dokładnie! Na przykład gdy używasz drabiny, dotykasz twarzy lub podnosisz coś z podłogi.',
								vn: 'Chính xác! Ví dụ, nếu bạn sử dụng cầu thang, chạm vào mặt, hoặc nhặt đồ gì đó từ sàn nhà.'
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Efter pauser`,
					en: `After breaks and being to the toilet`,
					pl: 'Po przerwach i przebywaniu w toalecie',
					vn: 'Sau giờ nghỉ và sau khi đi toilet'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Når jeg passerer en hygiejnesluse`,
					en: `When I pass a hygiene lock`,
					pl: 'Gdy przechodzę przez śluzę higieniczną',
					vn: 'Khi tôi đi qua một khóa vệ sinh'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Hver time`,
					en: `Every hour`,
					pl: 'Co godzinę',
					vn: 'Mỗi giờ một lần'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Hvert kvarter`,
					en: `Every fifteen minutes`,
					pl: 'Co piętnaście minut',
					vn: 'Mười lăm phút một lần'
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-lockers',
		background: 'dressing-room',
		text: {
			da: `Inden vi går ind i produktionen, så skal vi lige forbi skabene.`,
			en: `Before entering production, we have to talk about the lockers.`,
			pl: 'Przed wejściem do obszaru produkcji musimy porozmawiać o szafkach.',
			vn: 'Trước khi bước vào quá trình sản xuất, chúng ta cần nói về các tủ đựng đồ.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-lockers2',
		background: 'lockers',
		text: {
			da: `Du har et skab til privat tøj og et sted til arbejdstøj.`,
			en: `You have a locker for private clothes and one for work clothes.`,
			pl: 'Masz szafkę na twoje osobiste ubrania i szafkę na strój roboczy.',
			vn: 'Bạn có một tủ để đựng quần áo cá nhân và một tủ để đựng quần áo làm việc.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-work-locker',
		layout: 's1-m3-work-locker',
		background: 'lockers',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører IKKE til i skabet til privat tøj?`,
			en: `Which of the following do NOT belong in the locker for private clothes?`,
			pl: 'Jakie z następujących rzeczy NIE powinny być umieszczane w szafce na osobiste ubrania?',
			vn: 'Đồ vật nào KHÔNG nên đặt trong tủ đựng quần áo cá nhân?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // everyday shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // sandals
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // white work shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // hair net
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-private-locker',
		layout: 's1-m3-private-locker',
		background: 'lockers',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører til i skabet til privat tøj?`,
			en: `Which of the following belongs in the locker for private clothes?`,
			pl: 'Jakie z następujących rzeczy powinny być umieszczane w szafce na osobiste ubrania?',
			vn: 'Đồ vật nào NÊN đặt trong tủ đựng quần áo cá nhân?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // safety glasses
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // white working clothes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // neck wrap and hat
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-storage',
		background: 'lockers',
		text: {
			da: `Det er vigtigt at opbevare ting de rigtige steder. På den måde undgår du at værktøj, tøj og skabe bliver forurenet.`,
			en: `It's important to place things in the right place. That way you can keep tools, clothes, and lockers from getting contaminated.`,
			pl: 'Należy umieszczać rzeczy w przeznaczonych na nie miejscach. W ten sposób unikniesz skażenia narzędzi, ubrań i szafek.',
			vn: 'Việc đặt đồ đúng nơi rất quan trọng. Như vậy, bạn có thể tránh tình trạng công cụ, quần áo và tủ bị nhiễm bẩn.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-before-work',
		text: {
			da: `Hvad skal du altid gøre lige inden du går i gang med at arbejde?`,
			en: `What must you always do right before starting work?`,
			pl: 'Co należy zawsze robić przed rozpoczęciem pracy?',
			vn: 'Bạn luôn phải làm điều gì đúng trước khi bắt đầu công việc?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Tjekke at mit arbejdstøj og udstyr ikke er i stykker`,
					en: `Check that my work clothes and equipment aren't broken`,
					pl: 'Sprawdzić, czy moje ubrania robocze nie są zniszczone, a wyposażenie nie jest zepsute',
					vn: 'Kiểm tra xem quần áo và thiết bị làm việc của tôi có dấu hiệu hư hại không'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Give mester en high five`,
					en: `High-five my manager`,
					pl: 'Przybić piątkę kierownikowi',
					vn: 'Đập tay với người quản lý'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Synge delika-sangen`,
					en: `Sing the Delika song`,
					pl: 'Zaśpiewać piosenkę Delika',
					vn: 'Hát bài hát Delika'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Vaske hænder`,
					en: `Wash my hands`,
					pl: 'Umyć ręce',
					vn: 'Rửa tay của tôi'
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Det har du lige gjort i hygiejneslusen.`,
								en: `You just did that in the hygiene lock`,
								pl: 'Zrobiłeś(-aś) to przed chwilą w śluzie higienicznej',
								vn: 'Bạn vừa rửa tay tại khóa vệ sinh rồi'
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-person2',
		layout: 's1-m3-person2',
		background: 'dressing-room',
		text: {
			da: `Se om du kan spotte de 4 fejl på udstyret her.<br /><br />Tryk på dem og tryk så OK når du mener du har dem alle.`,
			en: `See if you can spot the 4 issues with the equipment here. Click OK when you are ready or if you can't find them all.`,
			pl: 'Spróbuj znaleźć 4 problemy z przedstawionym tu wyposażeniem. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.',
			vn: 'Hãy xem xét xem bạn có thể nhận diện 4 vấn đề với thiết bị này không. Nhấp OK khi bạn đã sẵn sàng hoặc nếu bạn không thể tìm thấy tất cả vấn đề.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'apron',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pants',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sleeve',
			}),

		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-ready',
		background: 'lockers',
		text: {
			da: `Nu er vi klar til at gå ind i produktionen. Det gør vi i næste modul.`,
			en: `Now we're ready to enter the production area. We'll do that in the next module.`,
			pl: 'Teraz możemy wejść do obszaru produkcji. Zrobimy to w następnym module.',
			vn: 'Bây giờ chúng ta đã sẵn sàng bước vào khu vực sản xuất. Chúng ta sẽ làm điều đó trong mô-đun tiếp theo.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-finish',
		background: 'lockers',
		text: {
			da: `Godt gået! Du er nu igennem første del af træningen. Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester. Nu fortsætter vi til anden del.`,
			en: `Well done! You have made it through the first part of the training. If you you are in doubt about anything, ask your manager or the teacher. Now let's continue to the second part.`,
			pl: 'Dobra robota! Udało ci się przejść pierwszą część szkolenia. W razie wątpliwości zapytaj szkoleniowca lub kierownika. Teraz przejdźmy do drugiej części.',
			vn: 'Rất tốt! Bạn đã hoàn thành phần đầu tiên của khóa đào tạo. Nếu bạn có bất kỳ sự nghi ngờ nào, hãy hỏi quản lý hoặc giảng viên. Bây giờ, hãy tiếp tục đến phần thứ hai.'
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m3-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};