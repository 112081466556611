const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-welcome',
		background: 'hygiene-lock',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal vi ind i selve produktionen. Husk du skal have tre stjerner for at gennemføre.`,
			en: `Welcome to the second part of the hygiene training. Now we are going to enter the production area. Remember, you need three stars to complete.`,
			pl: 'Witaj w drugiej części szkolenia z zasad higieny. Teraz wejdziemy do obszaru produkcji. Pamiętaj, musisz uzyskać trzy gwiazdki, aby ukończyć moduł.',
			vn: 'Chào mừng bạn đến phần thứ hai của khóa đào tạo vệ sinh. Bây giờ chúng ta sẽ bước vào khu vực sản xuất. Nhớ rằng bạn cần đạt được ba ngôi sao để hoàn thành.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-remember-hygiene-lock',
		background: 'hygiene-lock',
		text: {
			da: `Hvad skal du huske her inden du går ind i produktionen?`,
			en: `What must you remember here before entering production?`,
			pl: 'O czym musisz pamiętać przed wejściem do obszaru produkcji?',
			vn: 'Bạn cần nhớ điều gì trước khi bước vào khu vực sản xuất?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `At vaske hænder`,
					en: `Wash my hands`,
					pl: 'Umyc ręce',
					vn: 'Rửa tay của tôi'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `At rette på mit hårnet`,
					en: `Straighten my hairnet`,
					pl: 'Wyprostować moją siatkę do włosów',
					vn: 'Làm thẳng lưới buộc tóc của tôi'
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Det behøver du kun hvis det sidder dårligt. Men husk at vaske hænder bagefter.`,
								en: `Only if it doesn't fit properly. But remember to wash your hands afterwards. `,
								pl: 'Tylko jeśli nie jest odpowiednio założona. Ale pamiętaj, aby umyć potem ręce.',
								vn: 'Chỉ khi lưới buộc tóc bị lệch hoặc không che kín hết tóc. Nhưng hãy nhớ rửa tay ngay sau đó.'
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `At high five mine kollegaer`,
					en: `High-fiving my co-workers`,
					pl: 'Przybić piątkę współpracownikom',
					vn: 'Đập tay với đồng nghiệp của tôi'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `At sige det hemmelige kodeord`,
					en: `Say the secret password`,
					pl: 'Powiedzieć tajne hasło',
					vn: 'Nói mật khẩu bí mật'
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-wash-hands',
		background: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `Always wash your hands when passing a hygiene lock. What is the correct order to do the following in?`,
			pl: 'Zawsze należy myć ręce, gdy przechodzimy przez śluzę higieniczną. W jakiej kolejności trzeba wykonać następujące czynności?',
			vn: 'Bạn phải luôn rửa tay khi đi qua một khóa vệ sinh. Thứ tự đúng để thực hiện rửa tay là gì?'
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`,
					pl: 'Umyć ręce mydłem i wodą',
					vn: 'Rửa tay với xà phòng và nước'
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tør hænder med engangshåndklæde`,
					en: `Dry hands with paper towels`,
					pl: 'Wysuszyć ręce papierowymi ręcznikami',
					vn: 'Lau khô tay bằng khăn giấy'
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Smid engangshåndklæde i skraldespand`,
					en: `Toss paper towels in the bin`,
					pl: 'Wyrzucić papierowe ręczniki do kosza',
					vn: 'Bỏ khăn giấy vào thùng rác'
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Brug håndsprit`,
					en: `Use sanitiser`,
					pl: 'Użyć środka dezynfekującego',
					vn: 'Sử dụng chất khử trùng'
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-many-procedures',
		text: {
			da: `Så er vi inde i produktionen. Her er der mange procedurer som skal overholdes. For eksempel hvordan vi fylder vogne, kar og bakker korrekt.`,
			en: `Here in the production area there are lots of procedures to follow. For instance how to fill wagons, tubs, and trays correctly.`,
			pl: 'W obszarze produkcji obowiązuje wiele procedur, których trzeba przestrzegać. Przykładowo dotyczących tego, jak należy wypełniać wózki, skrzynie i tace.',
			vn: 'Tại khu vực sản xuất có nhiều quy trình cần tuân thủ. Ví dụ như cách đổ đầy các toa, thùng, và khay đúng cách.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-types',
		text: {
			da: `Der er tre forskellige måder vores produkter kan blive forurenet på: fysisk, kemisk og mikrobiologisk.`,
			en: `There are three different ways in which product can get contaminated: physically, chemically, and microbiologically.`,
			pl: 'Nasze produkty mogą ulec trzem typom skażenia: skażeniu fizycznemu, chemicznemu i mikrobiologicznemu.',
			vn: 'Có ba cách khác nhau khiến sản phẩm có thể bị nhiễm bẩn: vật lý, hóa học và vi khuẩn.'
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-reactions',
		text: {
			da: `Hvordan du skal reagere på forurening afhænger af, hvilken type det er. Lad os kigge nærmere på det.`,
			en: `What to do about it depends on the type of contamination. Let's take a closer look at it.`,
			pl: 'Sposób postępowania jest uzależniony od typu skażenia. Przyjrzyjmy się temu.',
			vn: 'Cách ứng phó với tình trạng nhiễm bẩn phụ thuộc vào loại nhiễm bẩn. Hãy xem xét kỹ hơn về điều đó.'
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-broken-lamp',
		text: {
			da: `Du finder rester af en blå indlægspose i et kar med kød. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `You find a piece of a blue inlay bag in a tub of meat. What should you do? Place the following actions in the right order.`,
			pl: 'Znajdujesz w pojemniku z mięsem niebieską torbę. Co należy zrobić? Umieść działania we właściwej kolejności.',
			vn: 'Bạn phát hiện một miếng túi lót màu xanh trong một thùng thịt. Bạn nên làm gì? Sắp xếp các hành động sau đây theo đúng thứ tự.'
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Stop produktionen`,
					en: `Stop the production`,
					pl: 'Wstrzymać produkcję',
					vn: 'Ngừng việc sản xuất'
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tilkald mester/kvalitet`,
					en: `Call for manager/quality`,
					pl: 'Wezwać kierownika / osobę odpowiedzialną za jakość',
					vn: 'Gọi quản lý/quản lý chất lượng'
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Afvent frigivelse fra mester/kvalitet`,
					en: `Wait for release from manager/quality`,
					pl: 'Poczekać na zielone światło od kierownika / osoby odpowiedzialnej za jakość',
					vn: 'Đợi thông báo từ quản lý/quản lý chất lượng'
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`,
					pl: 'Kontynuować pracę',
					vn: 'Tiếp tục công việc'
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-glove-hole',
		text: {
			da: `Du opdager at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You notice that the finger tip of one of your plastic gloves is missing. What should you do?`,
			pl: 'Zauważasz, że w jednej z twoich plastikowych rękawiczek brakuje końca palca. Co należy zrobić?',
			vn: 'Bạn nhận thấy đầu ngón tay của một chiếc găng tay nhựa bị mất. Bạn nên làm gì?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Informere min mester med det samme`,
					en: `Inform my immediate manager right away`,
					pl: 'Niezwłocznie poinformować bezpośredniego przełożonego',
					vn: 'Thông báo ngay cho người quản lý trực tiếp của tôi'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`,
					en: `Continue work. I can change glove in my next break`,
					pl: 'Kontynuować prace. Mogę zmienić rękawiczkę na następnej przerwie',
					vn: 'Tiếp tục công việc. Tôi có thể thay găng tay trong giờ nghỉ tiếp theo'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Skifte handske, så jeg kan arbejde videre`,
					en: `Change glove so I can continue work`,
					pl: 'Zmienić rękawiczkę, aby móc pracować dalej',
					vn: 'Thay găng tay để tôi có thể tiếp tục làm việc'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't need to change glove`,
					pl: 'Nie muszę zmieniać rękawiczki',
					vn: 'Tôi không cần thay găng tay'
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production-hall',
		layout: 's1-m2-production-hall',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 5 issues. Click OK when you are ready or if you can't find them all.`,
			pl: 'Kliknij 5 problemy. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.',
			vn: 'Nhấp vào 5 vấn đề. Nhấp OK khi bạn sẵn sàng hoặc nếu bạn không thể nhận diện tất cả vấn đề.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove-piece', // Stykke fra handske på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'knife', // knife on floor
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'person', // person without apron
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tub', // overfilled tub
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-dripping',
		text: {
			da: `Hvis der drypper fx kondensvand ned fra rør, så er det problematisk. Hvorfor?`,
			en: `If condensed water is dripping from pipes, you have to remove the products. Why is that?`,
			pl: 'Jeśli z rur kapie skondensowana woda, musisz usunąć produkty. Dlaczego?',
			vn: 'Nếu nước ngưng tụ nhỏ giọt từ đường ống, bạn phải loại bỏ sản phẩm. Tại sao lại vậy?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate product that follows`,
					pl: 'Woda może doprowadzić do skażenia produktów',
					vn: 'Nước nhỏ giọt có thể nhiễm bẩn sản phẩm xuất ra sau đó'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det er faktisk ikke et problem`,
					en: `The products can in fact stay on the belt`,
					pl: 'Produkty mogą zostać na taśmie',
					vn: 'Trên thực tế, các sản phẩm vẫn có thể nằm trên băng chuyền'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There's a risk of consumers getting sick`,
					pl: 'Istnieje ryzyko zachorowania konsumentów',
					vn: 'Có nguy cơ người tiêu dùng bị bệnh'
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-can-cleaning-agent',
		text: {
			da: `Du ser en dunk med rengøringsmiddel stå på båndet. Hvad gør du?`,
			en: `You spot a bottle of cleaner on the conveyer belt. What do you do?`,
			pl: 'Widzisz na taśmie butelkę środku czyszczącego. Co robisz?',
			vn: 'Bạn phát hiện một chai đựng chất tẩy rửa trên băng chuyền. Bạn sẽ làm gì?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Lader det stå`,
					en: `Leave it`,
					pl: 'Zostawić ją',
					vn: 'Để nó đi'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fjerner det og tilkalder så mester`,
					en: `Remove it and then call for a manager`,
					pl: 'Usunąć go i wezwać kierownika',
					vn: 'Loại bỏ chai khỏi băng chuyền rồi gọi người quản lý'
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Godt! Vi skal sikre os at vores produkter ikke bliver forurenet.`,
								en: `Good! We have to make sure our products aren't contaminated.`,
								pl: 'Dobrze! Musimy dopilnować, aby nasze produkty nie uległy skażeniu.',
								vn: 'Tốt! Chúng ta phải đảm bảo sản phẩm của mình không bị nhiễm bẩn.'
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Tilkalder mester`,
					en: `Call for my immediate manager`,
					pl: 'Wezwać bezpośredniego przełożonego',
					vn: 'Gọi cho người quản lý trực tiếp của tôi'
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Altid godt at spørge, men husk også at fjerne flasken.`,
								en: `It's always good to ask. But don't forget to remove the bottle.`,
								pl: 'Zawsze dobrze zapytać. Ale nie zapomnij usunąć butelki.',
								vn: 'Làm như vậy rất hữu ích. Tuy nhiên, đừng quên loại bỏ chai đó.'
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fjerner det`,
					en: `Remove it`,
					pl: 'Usunąć ją',
					vn: 'Loại bỏ chai đó.'
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-ask-manager',
		text: {
			da: `Hvis du er i tvivl om hvorvidt noget er blevet kemisk forurenet, så spørg din mester.`,
			en: `If you aren't sure whether or not something has been contaminated chemically, ask your manager.`,
			pl: 'Jeśli nie masz pewności, czy coś uległo skażeniu chemicznemu, zapytaj kierownika.',
			vn: 'Nếu bạn không chắc chắn liệu một thứ gì đó đã bị nhiễm bẩn hóa học hay không, hãy hỏi người quản lý của bạn.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-allergens',
		subtype: 'images',
		layout: 's1-m2-allergies',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Klik på fire allergener.`,
			en: `Contamination can be a problem for people with allergies. Click on four allergens.`,
			pl: 'Skażenie może być problemem dla osób z alergiami. Kliknij cztery alergeny.',
			vn: 'Nhiễm bẩn có thể là một vấn đề đối với những người có dị ứng. Hãy nhấp vào bốn chất gây dị ứng.'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Nuts
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Flour
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // strawberry 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // apple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // pineapple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // Milk
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, isCorrect: true // seeds and sennep glass
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-contamination-problem-why',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`,
			pl: 'Nasze produkty mogą ulec skażeniu na różne sposoby. Dlaczego skażenie jest problemem?',
			vn: 'Sản phẩm của chúng ta có thể bị nhiễm bẩn theo nhiều cách khác nhau. Tại sao nhiễm bẩn là một vấn đề?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`,
					pl: 'Skażenie może być niebezpieczne',
					vn: 'Nhiễm bẩn dẫn đến nguy hiểm'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can ruin the taste`,
					pl: 'Skażenie może zepsuć smak',
					vn: 'Nhiễm bẩn có thể làm hỏng mùi vị sản phẩm'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can cause unhappy customers`,
					pl: 'Skażenie może skutkować niezadowoleniem klientów',
					vn: 'Nhiễm bẩn có thể khiến khách hàng không hài lòng'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det kan give ekstra CO2-udledning`,
					en: `It emits more CO2`,
					pl: 'Skażenie powoduje większą emisję CO2',
					vn: 'Nhiễm bẩn thải ra nhiều CO2 hơn'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Det er faktisk ikke et stort problem`,
					en: `It's actually not a big problem`,
					pl: 'Właściwie nie jest to duży problem',
					vn: 'Thực ra đó không phải là vấn đề lớn'
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-spot-problems',
		text: {
			da: `Hygiejne er ekstremt vigtigt i produktionen. Og fordi du er tæt på produktet, er du den første der kan lægge mærke til problemer.`,
			en: `Hygiene is extremely important in production. And because you work near the products, you'll be the first to spot problems.`,
			pl: 'Higiena jest niezwykle ważna w produkcji. A ponieważ pracujesz blisko produktów, jesteś pierwszą osoba, która zauważy problemy.',
			vn: 'Vệ sinh rất quan trọng trong quá trình sản xuất. Ngoài ra, do bạn làm việc gần sản phẩm, bạn sẽ là người đầu tiên phát hiện ra vấn đề.'
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-golden-rule',
		text: {
			da: `Hvad er den gyldne regel, hvis du spotter noget, som måske er et problem?`,
			en: `What is the golden rule if you spot something that could be a problem?`,
			pl: 'Jaka jest złota zasada dotycząca zauważonych problemów?',
			vn: 'Nếu bạn phát hiện điều gì đó có thể trở thành một vấn đề, quy tắc vàng là gì?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Stands produktionen`,
					en: `Stop production`,
					pl: 'Wstrzymać produkcję',
					vn: 'Ngừng việc sản xuất'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Skriv en note`,
					en: `Write a note`,
					pl: 'Napisać uwagę',
					vn: 'Viết một lưu ý'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Smid alt kød i nærheden af problemet ud`,
					en: `Put all meat close to the problem in waste`,
					pl: 'Wyrzucić wszelkie znajdujące się w pobliżu problematycznego elementu mięso ',
					vn: 'Bỏ toàn bộ số thịt gần nơi xảy ra vấn đề vào thùng rác'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Spørg mester`,
					en: `Ask the closest manager`,
					pl: 'Zapytać znajdującego się najbliżej kierownika',
					vn: 'Hỏi ngay người quản lý gần nhất.'
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Lige præcis! Hvis du er i tvivl, så skal du bare spørge mester.`,
								en: `Exactly! When in doubt, don't hesitate to ask a manager.`,
								pl: 'Dokładnie! W razie wątpliwości zapytaj kierownika.',
								vn: 'Chính xác! Khi có nghi ngờ, đừng ngần ngại hỏi một người quản lý.'
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-extra-attention',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?
				<br /><br />Vælg 1 - og husk at læse alle svarmulighederne.`,
			en: `Why must we be especially aware of hygiene in production? 
				<br /><br />Choose 1 - and remember to read all the options:`,
			pl: 'Dlaczego higiena w obszarze produkcji jest szczególnie istotna?<br /><br />Wybierz 1 odpowiedź i pamiętaj, aby przeczytać wszystkie opcje:',
			vn: 'Tại sao chúng ta cần đặc biệt chú ý đến vệ sinh trong quá trình sản xuất?<br /><br />Chọn 1 - và hãy nhớ đọc tất cả các lựa chọn:'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Because of legal requirements`,
					pl: 'Ze względu na wymogi prawne',
					vn: 'Do các yêu cầu pháp lý'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because bad hygiene can make people sick`,
					pl: 'Ponieważ zła higiena może prowadzić do zachorowań',
					vn: 'Vì vệ sinh kém có thể khiến con người bị bệnh'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: 'Aby zapewnić wysoką jakość',
					vn: 'Để đảm bảo chất lượng cao'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the reasons above`,
					pl: 'Ze wszystkich powyższych przyczyn',
					vn: 'Vì tất cả những lý do trên'
				},
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-delivery',
		text: {
			da: `Vi mangler to ting. Den første handler om levering til fragtmænd.`,
			en: `Only two tasks remaining. The first one is about deliveries to freighters.`,
			pl: 'Zostały już tylko dwa zadania. Pierwsze z nich dotyczy dostaw na frachtowce.',
			vn: 'Chỉ còn hai nhiệm vụ nữa. Nhiệm vụ đầu tiên liên quan đến việc giao hàng cho tàu vận chuyển.'
		}
	}),	
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-delivery',
		layout: 's1-m2-delivery',
		text: {
			da: `Når du skal levere til fragtmænd er der især to ting som er vigtig at tjekke. Klik på de to fejl på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem.`,
			en: `When making a delivery to a freighter, two things are especially important to check. Click on the two problems in the image. Click OK when you are ready or if you can't spot them both.`,
			pl: 'Dostarczając produkty na frachtowce, należy sprawdzić dwie ważne kwestie. Kliknij dwa problemy widoczne na obrazie. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć obu problemów.',
			vn: 'Khi giao hàng cho một tàu vận chuyển, có hai điều đặc biệt quan trọng cần kiểm tra. Nhấp vào hai vấn đề trong hình ảnh. Nhấp OK khi bạn sẵn sàng hoặc nếu bạn không thể phát hiện cả hai.'
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'thermometer',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Nemlig! Bilen må ikke være for varm. Tjek altid at bilen har den rette temperatur. `,
								en: `Exactly! The van must not be too hot. Always check if it has the right temperature.`,
								pl: 'Dokładnie! W ciężarówce nie może być zbyt gorąco. Zawsze sprawdzaj, czy panuje w niej odpowiednia temperatura.',
								vn: 'Chính xác! Xe tải không được quá nóng. Luôn kiểm tra xem xe tải có ở mức nhiệt độ phù hợp hay không.'
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirt'
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-sidekick-training',
		text: {
			da: `Det sidste spørgsmål handler om sidemandsoplæring. Her er der ikke rigtige eller forkerte svar, og besvarelsen er anonym.`,
			en: `The final question is about peer-to-peer training. In this question there are no right or wrong answers, and you answer anonymously.`,
			pl: 'Ostatnie pytanie dotyczy partnerskiej nauki. W tym pytaniu nie ma prawidłowych ani nieprawidłowych odpowiedzi, a udzielona przez ciebie odpowiedź będzie anonimowa.',
			vn: 'Câu hỏi cuối cùng liên quan đến đào tạo ngang hàng. Trong câu hỏi này, không có câu trả lời đúng hoặc sai, và bạn sẽ trả lời ẩn danh.'
		}
	}),	
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-sidekick-training',
		shuffleOptions: false,
		title: {
			da: 'Sidemandsoplæring',
			en: 'Peer-to-peer training',
			pl: 'Szkolenie peer-to-peer',
			vn: 'Đào tạo ngang hàng'
		},
		text: {
			da: `Hvor enig er du i, at den sidemandsoplæring, du har modtaget, var god?`,
			en: `How much do you agree on the following statement:
				<br />The peer-to-peer training I have experienced has been good?`,
			pl: 'W jakim stopniu zgadzasz się z następującym stwierdzeniem:<br />Nauka partnerska, w której uczestniczyłem(-am), przebiegała dobrze?',
			vn: 'Mức độ đồng ý của bạn là bao nhiêu với tuyên bố sau đây:<br />Quá trình đào tạo ngang hàng mà tôi đã trải qua có tốt không?'
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: 'Zdecydowanie się zgadzam',
					vn: 'Rất đồng ý'
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: 'Zgadzam się',
					vn: 'Đồng ý'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: 'Nie mam zdania',
					vn: 'Không đồng ý và cũng không phản đối.'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: 'Nie zgadzam się',
					vn: 'Không đồng ý'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: 'Zdecydowanie się nie zgadzam',
					vn: 'Rất không đồng ý'
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Jeg har ikke modtaget sidemandsoplæring`,
					en: `I have not experienced peer-to-peer training`,
					pl: 'Nie uczestniczyłem(-am) w nauce partnerskiej',
					vn: 'Tôi chưa được trải qua đào tạo ngang hàng trực tiếp'
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-finish',
		text: {
			da: `Godt gået! 
				<br /><br />Du er nu igennem træningen. Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester. 
				<br /><br />God arbejdslyst derude!`,
			en: `Well done!
				<br /><br />You've completed the training. If in doubt about anything, ask the teacher or your manager. 
				<br /><br />Enjoy your work!`,
			pl: 'Dobra robota!<br /><br />Ukończono szkolenie. W razie wątpliwości zapytaj szkoleniowca lub kierownika.<br /><br />Miłej pracy!',
			vn: 'Rất tốt!<br /><br />Bạn đã hoàn thành khóa đào tạo. Nếu có bất kỳ sự nghi ngờ nào, hãy hỏi giảng viên hoặc người quản lý của bạn.'
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m4-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};