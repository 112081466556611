import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import {
	createNewPlayerModule,
	getPlayerModulesData,
	getPlayerModuleSessionData,
	getPlayerModuleIndex,
	getPlayerModuleSessionIndex,
	startNewSession, 
	getLastPlayedTaskId, 
	checkIfModuleSessionIsCompleted
} from 'helpers/module-helper';
import {checkIfSkipTaskConditionsAreMet, getNextTaskId} from 'helpers/task-helper';
import {getTimeSinceLastActivity} from 'helpers/time-helper';
import {getModuleMaxPoints, getNumberOfFilledStars} from 'helpers/points-helper';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import Loading from 'components/loading/loading';
import Module from './module';
import Results from './engines/results/results';
import ImageLoader from 'components/ui/image-loader/image-loader';

class ModuleController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showResults: false,
			isStartingNewSession: false,
			taskId: null,
			feedbackData: null,
			popupData: null,
			queuedEffects: [],
			streakType: 'error', // error / success
			streakValue: 0,
			loggedTime: 0,
			timestamp: Date.now()
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Get player modules data (all modules) */
		let playerModulesData = getPlayerModulesData(this.props.userData);

		/* Get player data for this module */
		let moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});

		/* Check if curreent module session is completed */
		const moduleSession = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
		const moduleSessionIsCompleted = checkIfModuleSessionIsCompleted(moduleSession);

		/* Show results if module session is completed */
		this.setState({showResults: moduleSessionIsCompleted});

		if (moduleIndex < 0) {
			/* Player has just started first session in module, create player module data */
			playerModulesData.push(createNewPlayerModule(this.props.moduleId));
			this.props.updateUser({modules: playerModulesData}).then(() => {
				/* Go to first task in module, automatically sets isLoading to false */
				this.handleGoToTask(playerModulesData[playerModulesData.length - 1].sessions[0].currentTaskId);
			});
		} else {
			/* Player has alrady started module, go to last played task, automatically sets isLoading to false */
			const taskId = getLastPlayedTaskId(this.props.moduleId, this.props.userData);
			if (taskId) this.handleGoToTask(taskId);
		}
	};


	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {		
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Update logged time for session */
		let playerModulesData = getPlayerModulesData(this.props.userData);
		const moduleIndex = getPlayerModuleIndex(this.props.userData, this.props.moduleId);
		const sessionIndex = getPlayerModuleSessionIndex(this.props.userData, this.props.moduleId);

		if (moduleIndex >= 0 && sessionIndex >= 0) {
			const loggedTime = this.updateLoggedTime();
			let playerSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
			const sessionIsCompleted = checkIfModuleSessionIsCompleted(playerSessionData);
			if (!sessionIsCompleted) {
				if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) {
					playerSessionData.milisecondsPlayed = 0;
				}
				playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;	
				playerModulesData[moduleIndex].sessions[sessionIndex] = playerSessionData;
				this.props.updateUser({modules: playerModulesData});	
			}
		}
	};

	/**
	 * Update logged time
	 * @param {bool} resetLoggedTime
	 */
	updateLoggedTime = (resetLoggedTime = false) => {
		/* Get number of miliseconds since last update */
		const miliseconds = getTimeSinceLastActivity(this.state.timestamp);
		const newLoggedTime = this.state.loggedTime + miliseconds;

		this.setState({loggedTime: (resetLoggedTime ? 0 : newLoggedTime), timestamp: Date.now()});
		return newLoggedTime;
	};
	

	/**
	 * Update current task id for module in player data
	 */
	updateCurrentTaskId = (taskId) => {
		if (!taskId) return;

		/* Get player modules data */
		let playerModulesData = getPlayerModulesData(this.props.userData);

		/* Get module index in player data */
		const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});

		/* Get module session */
		let moduleSession = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
		if (moduleSession) {
			moduleSession.currentTaskId = taskId;
			playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = moduleSession;
			this.props.updateUser({modules: playerModulesData});	
		} else {
			console.error('This should not happen!');
		}
	};

	/**
	 * Go to module task
	 * @param {number} taskId 
	 */
	handleGoToTask = (taskId) => {
		/* Check if task should be skipped */
		const skipTaskConditionsAreMet = checkIfSkipTaskConditionsAreMet(
			taskId, this.props.moduleId, this.props.gameData, this.props.userData
		);

		if (skipTaskConditionsAreMet) {
			/* Skip task */
			const nextTaskId = getNextTaskId(taskId, this.props.moduleId, this.props.gameData);
			if (nextTaskId) {
				this.handleGoToTask(nextTaskId);
			}
		} else {
			/* Go to task */
			this.setState({taskId, isLoading: false}, () => {
				/* Scroll to page top */
				this.props.scrollToTop();

				/* Update current task in player data */
				this.updateCurrentTaskId(taskId);
			});
		}
	};

	/**
	 * Handle instant task effects 
	 * They are triggered during a task (e.g. by selecting an option in multiple choice)
	 * @param {array} effects 
	 */
	handleInstantTaskEffects = (effects) => {
		/* Get queued effects */
		let queuedEffects = (this.state.queuedEffects && this.state.queuedEffects.length > 0 
			? [...this.state.queuedEffects] 
			: []
		);

		/* Get streak data */
		let newStreakType = this.state.streakType;
		let newStreakValue = this.state.streakValue;

		/* Loop over effects */
		effects.forEach((effect) => {
			if (
				(effect.type === 'feedback' && effect.feedback) ||
				(effect.type === 'popup' && effect.popup)
			) {
				/* Feedback / popup */
				queuedEffects.push(effect);
			}			
		});
	
		if (queuedEffects.length > 0) {
			/* Update streak data & start showing queued instant effects */
			this.setState({queuedEffects, streakType: newStreakType, streakValue: newStreakValue}, () => {
				this.processQueuedEffects(false);
			});
		} else {
			/* Update streak data */
			this.setState({streakType: newStreakType, streakValue: newStreakValue});
		}
	};

	/**
	 * Process queued instant effects
	 */
	processQueuedEffects = (delayNextEffect = true) => {
		/* Close all effects */
		this.setState({feedbackData: null, popupData: null}, () => {
			let queuedEffects = [...this.state.queuedEffects];
			if (queuedEffects.length === 0) {
				/* Queue is empty */
				const scenarioId = (this.props.gameData.scenarioId ? this.props.gameData.scenarioId : 'scenario-1');
				const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
				const moduleData = scenarioData.modulesData.find((m) => {return m.id === this.props.moduleId;});
				const taskIndex = moduleData.tasks.findIndex((task) => {return task.id === this.state.taskId;});
				const playerModuleSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
				const moduleSessionIsCompleted = checkIfModuleSessionIsCompleted(playerModuleSessionData);
				if (moduleSessionIsCompleted && taskIndex + 1 >= moduleData.tasks.length) {
					/* Last task in module completed, show results */
					this.setState({showResults: true});
				}
				
			} else {
				/* Display next effect */
				if (
					queuedEffects[0].type === 'feedback' ||
					queuedEffects[0].type === 'popup'
				) {
					/* Feedback or popup */
					const newQueuedEffects = queuedEffects.slice(1);
					const feedbackData = (queuedEffects[0].type === 'feedback' ? queuedEffects[0].feedback : null);
					const popupData = (queuedEffects[0].type === 'popup' ? queuedEffects[0].popup : null);

					const delay = (delayNextEffect 
						? (queuedEffects[0].type === 'feedback' ? appConfig.feedbackDelay : appConfig.popupDelay)
						: 0
					);
					if (this.timeout) clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						this.setState({feedbackData, popupData, queuedEffects: newQueuedEffects});
					}, delay);	
				}
			}
		});
	};

	/**
	 * Handle a completed task
	 * @param {string} type 
	 * @param {number} points 
	 * @param {number} errors 
	 * @param {*object} taskData 
	 */
	handleCompleteTask = (type, points, errors, effects, taskData) => {	
		/* Get logged time, reset */
		const loggedTime = this.updateLoggedTime(true);

		/* Get streak data */ 
		let newStreakType = this.state.streakType;
		let newStreakValue = this.state.streakValue;

		/* Get task data */	
		const scenarioId = (this.props.gameData.scenarioId ? this.props.gameData.scenarioId : 'scenario-1');
		const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
		const moduleData = scenarioData.modulesData.find((m) => {return m.id === this.props.moduleId;});
		const taskId = (moduleData && moduleData.tasks.some((task) => {return task.id === this.state.taskId;})
			? moduleData.tasks.find((task) => {return task.id === this.state.taskId;}).taskId
			: null
		);
		if (!taskId) {
			console.error('Completing task - but no task data found. Should not happen!');
			console.error('Task id: ', this.state.taskId);
			return;
		}

		/* Get player data */
		let playerModulesData = getPlayerModulesData(this.props.userData);
		const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});
		let playerSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);

		if (!playerSessionData) {
			console.error('Completing task - but no module / session found in database. Should not happen!');
			return;
		}

		/* Update logged time for session */
		if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
		playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;	

		/* Prepare array of effects to show */
		let queuedEffects = [];

		/* Handle effects */
		effects.forEach((effect) => {
			if (
				(effect.type === 'feedback' && effect.feedback) ||
				(effect.type === 'popup' && effect.popup)
			) {
				/* Feedback / popup */
				queuedEffects.push(effect);
			}
		});

		/* Update total points in module */
		playerSessionData.points += points;

		/* Add completed task to module */
		let taskObj = Object.assign({}, {
			isCompleted: true,
			taskId: taskId,
			type: type,
			points: points,
			errors: errors
		}, (taskData && type !== 'survey' ? taskData : {}));
		if (!playerSessionData.tasks) playerSessionData.tasks = [];
		playerSessionData.tasks.push(taskObj);

		/* Update player data */
		playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = playerSessionData;
		this.props.updateUser({modules: playerModulesData});

		/* Update survey data */
		if (type === 'survey') {
			const surveyObj = {
				gameId: this.props.gameData.id,
				moduleId: this.props.moduleId,
				taskId: taskId,
				selectedOptionIds: (taskData && taskData.selectedOptionIds ? taskData.selectedOptionIds : []),
				created: dayjs(new Date()).format('YYYY-MM-DD')
			};
			this.props.saveSurveyResponse(surveyObj);
		}

		if (queuedEffects.length > 0) {
			/* Update streak data & start showing queued effects */
			this.setState({queuedEffects, streakType: newStreakType, streakValue: newStreakValue}, () => {
				this.processQueuedEffects(false);
			});
		} else {
			/* Update streak data */
			this.setState({streakType: newStreakType, streakValue: newStreakValue});
		}
	};

	/**
	 * Complete module session
	 */
	handleCompleteModuleSession = () => {
		/* Get logged time, reset */
		const loggedTime = this.updateLoggedTime(true);

		/* Get player data */
		let playerModulesData = getPlayerModulesData(this.props.userData);
		const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});
		let playerSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);

		/* Update logged time & isCompleted flag */
		if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
		playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;
		playerSessionData.isCompleted = true;
		
		/* Check if number of required stars achieved */
		const scenarioId = (this.props.gameData.scenarioId ? this.props.gameData.scenarioId : 'scenario-1');
		const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
		const moduleData = scenarioData.modulesData.find((m) => {return m.id === this.props.moduleId;});
		const numberOfFilledStars = getNumberOfFilledStars(
			playerSessionData.points,
			getModuleMaxPoints(this.props.moduleId)
		);
		if (numberOfFilledStars >= moduleData.minStars && !playerModulesData[moduleIndex].completedDate) {
			playerModulesData[moduleIndex].completedDate = dayjs(new Date()).format('YYYY-MM-DD');
		}

		/* Update max points and stars for module */
		playerModulesData[moduleIndex].maxPoints = Math.max(
			(playerModulesData[moduleIndex].maxPoints ? playerModulesData[moduleIndex].maxPoints : 0),
			playerSessionData.points
		);
		playerModulesData[moduleIndex].maxStars = Math.max(
			(playerModulesData[moduleIndex].maxStars ? playerModulesData[moduleIndex].maxStars : 0),
			numberOfFilledStars
		);

		/* Check if failed 3rd attempt (or 6th or 9th or ...) */
		if (playerModulesData[moduleIndex].sessions.length % 3 === 0 && !playerModulesData[moduleIndex].completedDate) {
			playerModulesData[moduleIndex].disabledDate = dayjs(new Date()).format('YYYY-MM-DD');
		}

		/* Update player data */
		playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = playerSessionData;
		this.props.updateUser({modules: playerModulesData}).then (() => {
			this.setState({showResults: true});
		});
	};

	/**
	 * Handle reset module
	 */
	handleStartNewSession = () => {
		this.setState({isStartingNewSession: true, loggedTime: 0, timestamp: Date.now()}, () => {
			const playerModulesData = startNewSession(this.props.moduleId, this.props.userData);
			if (playerModulesData) {
				/* Reset module */
				this.props.updateUser({modules: playerModulesData}).then(() => {

					const scenarioId = (this.props.gameData.scenarioId ? this.props.gameData.scenarioId : 'scenario-1');
					const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
					const moduleData = scenarioData.modulesData.find((m) => {return m.id === this.props.moduleId;});
					const taskId = (moduleData && moduleData.tasks && moduleData.tasks.length > 0 
						? moduleData.tasks[0].id
						: null
					);
					/* Hide result, go to first task in module */
					this.setState({
						isStartingNewSession: false,
						showResults: false,
						taskId: taskId,
					});
				});
			} else {
				/* Module not found (should not happen) */
				this.setState({isStartingNewSession: false});
			}
		});
	};


	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-module" languageId={this.props.languageId} />
			);
		};

		/* Get scenario id and data */
		const scenarioId = (this.props.gameData.scenarioId ? this.props.gameData.scenarioId : 'scenario-1');
		const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});

		/* Scenario not found */
		if (!scenarioData) {
			return <div>{'Unknown scenario id'}: {scenarioId}</div>;
		}

		/* Get module and module task data */
		const moduleData = scenarioData.modulesData.find((m) => {return m.id === this.props.moduleId;});

		/* Module not found */
		if (!moduleData) {
			return <div>{'Unknown module id'}: {this.props.moduleId}</div>;
		}

		/* Show results page */
		if (this.state.showResults) {
			const backgroundId = moduleData.tasks[moduleData.tasks.length - 1].background;
			return (
				<Results 
					isStartingNewSession={this.state.isStartingNewSession}
					showModuleIsLockedPopup={this.state.showModuleIsLockedPopup}
					languageId={this.props.languageId}
					moduleData={moduleData} 
					userData={this.props.userData} 
					handleGoToGamePage={this.props.handleGoToGamePage}
					handleStartNewSession={this.handleStartNewSession}
					background={backgroundId ? backgroundId : ''}
				/>
			);
		}

		/* Get task data */
		const taskData = (moduleData 
			? moduleData.tasks.find((task) => {return task.id === this.state.taskId;})
			: null
		);
		if (!taskData) {
			console.error(this.props.moduleId);
			console.error(this.state.taskId);
			return (
				<div>{errorUiTexts.unknownModuleTaskId}: {this.state.taskId}</div>
			);
		}
		
		/* Render component */
		return (
			<>
				<Module 
					hasQueuedEffects={this.state.queuedEffects.length > 0 || this.state.queuedEffects.length > 0}
					languageId={this.props.languageId}
					gameData={this.props.gameData}
					moduleData={moduleData} 
					taskData={taskData}
					playerData={this.props.userData}
					feedbackData={this.state.feedbackData}
					popupData={this.state.popupData}
					updateLoggedTime={this.updateLoggedTime}
					handleGoToTask={this.handleGoToTask}
					handleInstantTaskEffects={this.handleInstantTaskEffects}
					handleCompleteTask={this.handleCompleteTask}
					handleGoToGamePage={this.props.handleGoToGamePage}
					processQueuedEffects={this.processQueuedEffects}
					handleCompleteModuleSession={this.handleCompleteModuleSession}
				/>
				<ImageLoader type={'module-' + this.props.moduleId} />
			</>
		);
	}
}

ModuleController.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	moduleId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	handleGoToGamePage: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	saveSurveyResponse: PropTypes.func.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default ModuleController;
