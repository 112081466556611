const modulesData = [
	{
		id: 's1-m1',
		background: 'dressing-room',
		title: {
			da: 'Hygiejnetræning A',
			en: 'Hygiene training A',
			pl: 'Szkolenie higieniczne A',
			vn: 'Tập huấn vệ sinh A'
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
	{
		id: 's1-m2',
		background: 'processing',
		title: {
			da: 'Hygiejnetræning B',
			en: 'Hygiene training B',
			pl: 'Szkolenie higieniczne B',
			vn: 'Tập huấn vệ sinh B'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m1'],
		tasks: require('./modules/module-2').moduleTasks,
	},
	{
		id: 's1-m3',
		background: 'processing',
		title: {
			da: 'Gentræning A',
			en: 'Repetition A',
			pl: 'Powtórzenie A',
			vn: 'Lặp lại A'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m2'],
		requiredWaitingDays: [7],
		tasks: require('./modules/module-3').moduleTasks,
	},
	{
		isPublished: true,
		id: 's1-m4',
		background: 'processing',
		title: {
			da: 'Gentræning B',
			en: 'Repetition B',
			pl: 'Powtórzenie B',
			vn: 'Lặp lại B'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m3'],
		tasks: require('./modules/module-4').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's1-mg1',
		courseNumber: '50000000',
		title: {
			da: 'Hygiejne og processing',
			en: 'Hygiene and processing',
			pl: 'Szkolenie higieniczne',
			vn: 'Tập huấn vệ sinh'
		},
		moduleIds: [
			's1-m1',
			's1-m2'
		]
	},
	{
		id: 's1-mg2',
		courseNumber: '50000510',
		title: {
			da: 'Gentræning A & B',
			en: 'Repetition A & B',
			pl: 'Powtórzenie A & B',
			vn: 'Sự lặp lại A & B'
		},
		moduleIds: [
			's1-m3',
			's1-m4'
		]
	}
];

export {
	modulesData,
	moduleGroups
};